import React from 'react';

import OnprogressFunction from '../../components/OnprogressFunction';
// import ProfitHeader from 'components/ProfitReport/ProfitHeader';

function ProfitBody(): JSX.Element {
  return (
    <div>
      {/*<ProfitHeader />*/}
      <OnprogressFunction />
    </div>
  );
}

export default ProfitBody;
