export const getMonths = () => {
  const listMonth = [];
  listMonth.push({ value: 1, label: 1 });
  listMonth.push({ value: 2, label: 2 });
  listMonth.push({ value: 3, label: 3 });
  listMonth.push({ value: 4, label: 4 });
  listMonth.push({ value: 5, label: 5 });
  listMonth.push({ value: 6, label: 6 });
  listMonth.push({ value: 7, label: 7 });
  listMonth.push({ value: 8, label: 8 });
  listMonth.push({ value: 9, label: 9 });
  listMonth.push({ value: 10, label: 10 });
  listMonth.push({ value: 11, label: 11 });
  listMonth.push({ value: 12, label: 12 });
  return listMonth;
};

export const getYear = () => {
  const yearNow = new Date().getFullYear();
  const listYear = [];
  listYear.push({ value: yearNow + 1, label: yearNow + 1 });
  listYear.push({ value: yearNow, label: yearNow });
  listYear.push({ value: yearNow - 1, label: yearNow - 1 });
  listYear.push({ value: yearNow - 2, label: yearNow - 2 });
  return listYear;
};
