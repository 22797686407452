import GoodsSyntheticBody from 'containers/GoodsSyntheticBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function GoodsSyntheticPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <GoodsSyntheticBody />
    </div>
  );
}

export default GoodsSyntheticPage;
