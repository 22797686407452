import { Button, Grid } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import React from 'react';

import style from './style.module.scss';

type Props = {
  total: any;
  actionExport: () => void;
  actionPreview?: () => void;
  actionSave?: () => void;
};

function Footer({ total, actionExport, actionPreview, actionSave }: Props): JSX.Element {
  return (
    <div className={style.footerContainer}>
      <div className={style.input}>
        <InputCustom
          label='Tổng tiền'
          value={total.toLocaleString('it-IT', {
            style: 'currency',
            currency: 'VND',
          })}
          handleChange={() => undefined}
        />
      </div>
      <Grid container spacing={6} style={{ flexDirection: 'row' }}>
        <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='primary' onClick={actionPreview}>
            Xem Hoá Đơn
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='primary' onClick={actionSave}>
            Lưu Hoá Đơn
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='primary' onClick={actionExport}>
            Xuất Excel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
