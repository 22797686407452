import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RepairImg } from '../../assets/images';
import { AppRoutes } from '../../helpers/app.routes';
import HandledImage from '../HandledImage';

function OnprogressFunction(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '4%' }}>
      <h3>Tính năng đang phát triển</h3>
      <HandledImage src={RepairImg} />

      <div>
        <Button
          variant='text'
          style={{ paddingLeft: 30, paddingRight: 30 }}
          onClick={() => {
            navigate(AppRoutes.home);
          }}
        >
          Quay lại trang chủ
        </Button>
      </div>
    </div>
  );
}

export default OnprogressFunction;
