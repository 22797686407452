import { Checkbox, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import paginate from 'Common';
import React, { useEffect, useState } from 'react';

import { PAGE_SIZE } from '../../../constants';
import { colors } from '../../../constants/colors';

type Props = {
  data: any;
  choseSchool: (bill: any) => void;
};

function SchoolTable({ data, choseSchool }: Props): JSX.Element {
  const [row_, setRow_] = useState<any>(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [dataPag, setDataPag] = useState(paginate(data, PAGE_SIZE, page));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setDataPag(paginate(data, rowsPerPage, newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDataPag(paginate(data, parseInt(event.target.value, 10), 0));
    setPage(0);
  };

  useEffect(() => {
    setDataPag(paginate(data, PAGE_SIZE, 0));
  }, [data]);

  return (
    <div style={{ marginBottom: 40 }}>
      <h3>Danh sách trường học</h3>
      <TableContainer component={Paper} sx={{ width: { xs: '96vw', sm: 'unset' } }}>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Mã trường</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Tên trường
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Địa chỉ
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Huyện
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Hoạt động
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Số ĐT
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Email
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Xe
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPag?.map((row: any, index: number) => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  ':hover': { backgroundColor: '#EFEFEF' },
                }}
                onClick={() => {
                  choseSchool(row);
                  setRow_(index);
                }}
                key={row.id}
                style={{ cursor: 'pointer', backgroundColor: index === row_ ? colors.chooseItem : 'transparent' }}
              >
                <TableCell>{row?.code}</TableCell>
                <TableCell align='left'>{row?.name}</TableCell>
                <TableCell align='left'>{row?.address}</TableCell>
                <TableCell align='left'>{row?.district}</TableCell>
                <TableCell align='left'>
                  <Checkbox checked={row?.status} disabled style={{ padding: 0, margin: 0 }} />
                </TableCell>
                <TableCell align='left'>{row?.phoneNumber}</TableCell>
                <TableCell align='left'>{row?.email}</TableCell>
                <TableCell align='left'>{row?.truck?.code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={data?.length}
          component='div'
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default SchoolTable;
