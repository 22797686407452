import ProfitBody from 'containers/ProfitBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function ProfitPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <ProfitBody />
    </div>
  );
}

export default ProfitPage;
