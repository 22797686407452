import WarehouseFooter from 'components/Warehouse/WarehouseFooter';
import React from 'react';

function ProposalBody(): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <WarehouseFooter />
    </div>
  );
}
export default ProposalBody;
