import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { getMonths, getYear } from 'constants/date';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import { paramsFun } from '../../../helpers/help';
import PreviewPDF from '../../PreviewPDF';

function MonthlyReportHeader({ setData, setDetailData }: any): JSX.Element {
  const [school, setSchool] = React.useState('');
  const [month, setMonth] = React.useState(new Date().getMonth().toString());
  const [year, setYear] = React.useState(new Date().getFullYear().toString());
  const [schools, setSchools] = useState<any[]>([]);
  const [months, setMonths] = useState<any[]>([]);
  const [years, setYears] = useState<any[]>([]);
  const [fileLink, setFileLink] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const handleChangeSchool = (event: SelectChangeEvent) => {
    setSchool(event.target.value as string);
  };

  const handleSetMonth = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };

  const handleSetYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  useEffect(() => {
    getItems();
    setMonths(getMonths());
    setYears(getYear());
  }, []);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getAllCustomer();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.name });
      });
      setSchools(a);
    }
  };

  const findAction = async () => {
    if (!school) return toast('Hãy chọn ngày để xuất báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/month/excel?' +
      paramsFun({
        customerId: school,
        month,
        year,
      });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const findActionPDF = async () => {
    if (!school) return toast('Hãy chọn ngày để xuất báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/month?' +
      paramsFun({
        customerId: school,
        month,
        year,
      });
    setFilePreview(link);
    setTitle('Công nợ khách hàng: ');
    // window.open(link, '_blank');
  };

  const findActionDetail = async () => {
    if (!school) return toast('Hãy chọn ngày để xuất báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/month-detail/excel?' +
      paramsFun({
        customerId: school,
        month,
        year,
      });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const findActionDetailPDF = async () => {
    if (!school) return toast('Hãy chọn ngày để xuất báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/month-detail?' +
      paramsFun({
        customerId: school,
        month,
        year,
      });
    setFilePreview(link);
    setTitle('Công nợ khách hàng chi tiết: ');
    // window.open(link, '_blank');
  };

  const onShowInfo = async () => {
    if (!school) return toast('Hãy chọn ngày để xem báo cáo!');
    const result: IRestApiResponse = await Services.getMonthReport({
      customerId: school,
      month,
      year,
    });
    if (!result || !result.success) return;
    setData(result.data?.monthItems || []);
    setDetailData(result.data?.detailItems || []);
    if (!result?.data) return toast('Không có dữ liệu!');
  };

  return (
    <div>
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={school} handleChange={handleChangeSchool} data={schools} label='Trường học' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={month} handleChange={handleSetMonth} data={months} label='Tháng' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={year} handleChange={handleSetYear} data={years} label='Năm' />
        </Grid>
      </Grid>
      <Button variant='contained' style={{ margin: 10, marginTop: 20 }} onClick={findActionDetail}>
        TẢI FILE CÔNG NỢ KH CHI TIẾT
      </Button>
      <Button variant='contained' style={{ margin: 10, marginTop: 20 }} onClick={findAction}>
        TẢI FILE XEM CÔNG NỢ KH
      </Button>
      <Button variant='contained' style={{ margin: 10, marginTop: 20 }} onClick={findActionDetailPDF}>
        XEM CÔNG NỢ KH CHI TIẾT
      </Button>
      <Button variant='contained' style={{ margin: 10, marginTop: 20 }} onClick={findActionPDF}>
        XEM CÔNG NỢ KH
      </Button>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default MonthlyReportHeader;
