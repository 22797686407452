import axios from 'axios';
import { toast } from 'react-toastify';

const _axios = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 3000,
  // headers: { 'X-Custom-Header': 'foobar' },
} as any);

// Add a request interceptor
_axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('accessToken');

  if (token) config.headers!['x-api-key'] = token;

  return config;
});

_axios.interceptors.response.use(
  (response) => {
    if (response.data?.error?.message === 'You are not logged in yet') {
      toast('You are not logged in yet');
      return window.location.replace('/');
    }
    return response.data;
  },
  function (error) {
    if (error.response?.data?.error.message) {
      toast(error.response?.data?.error.message);
    } else {
      toast(error.message);
    }

    return Promise.reject(error);
  }
);

export default _axios;
