import TypeBody from 'containers/TypeBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function TypePage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <TypeBody />
    </div>
  );
}

export default TypePage;
