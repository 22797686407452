import { Button, Grid, SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { paramsFun } from 'helpers/help';
import DatePickCustom from 'presents/DatePickCustom/Index';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import PreviewPDF from '../../PreviewPDF';

type Props = {
  productEdit?: any;
  okeAction: (productEdit: any) => void;
  reload?: any;
  keyword?: string;
};

const initialState = {
  id: undefined,
  code: '',
  name: '',
  quantityNew: '0',
  quantity: 0,
  importPrice: 0,
  specifications: '',
};

function WarehouseHeader({ productEdit, keyword, reload }: Props): JSX.Element {
  const [stateSP, setStateSP] = useState(initialState);
  const [fileLink, setFileLink] = useState('');
  const [date, setDate] = React.useState<any>(new Date());
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    setStateSP({ ...stateSP, ...productEdit });
  }, [productEdit]);

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateSP({ ...stateSP, [idElement]: event.target.value });
  };

  const importGoods = async () => {
    if (!stateSP || !stateSP.id) {
      return;
    }

    if (!stateSP.quantity || stateSP.quantity <= 0) {
      toast('Số lượng nhập phải lớn hơn 0');
      return;
    }

    const result: IRestApiResponse = await Services.importGoods({
      date: dayjs().toString(),
      detail: [
        {
          itemsId: stateSP.id,
          importQuantity: stateSP.quantity,
          importPrice: stateSP.importPrice,
        },
      ],
    } as any);
    if (!result || !result.success) return;
    if (result.success) {
      setStateSP(initialState);
      reload(keyword);
      toast('Nhập kho thành công');
    }
  };

  const viewInventoryReport = async () => {
    // if (!car) return toast('Bạn cần chọn xe để xuất Báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/goods-received/report?' +
      paramsFun({ date: dayjs().toString(), productId: stateSP.id });
    setFilePreview(link);
    setTitle('Thống kê tồn kho theo sản phẩm: ');
    // window.open(link, '_blank');
  };

  const handleChange = async (newValue: any) => {
    setDate(newValue);
  };

  return (
    <div>
      <h3>Nhập sản phẩm vào kho</h3>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={2}>
          <DatePickCustom label='Ngày' value={date} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom label='Mã sản phẩm' value={stateSP?.code} disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputCustom label='Tên sản phẩm' value={stateSP?.name} disabled />
        </Grid>

        <Grid item xs={12} sm={2}>
          <InputCustom
            label='Số lượng nhập'
            value={stateSP?.quantity.toString()}
            handleChange={(e) => handleChangeInputState(e, 'quantity')}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <InputCustom
            label='Giá nhập'
            value={stateSP?.importPrice.toLocaleString('it-IT')}
            handleChange={(e) => handleChangeInputState(e, 'importPrice')}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <InputCustom label='Đơn vị tính' value={stateSP?.specifications} disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='contained' onClick={importGoods}>
              Nhập vào kho
            </Button>
            <Button variant='contained' onClick={viewInventoryReport}>
              Xuất thống kê tồn kho
            </Button>
          </div>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default WarehouseHeader;
