import MonthlyReportBody from 'containers/MonthlyReportBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function MonthlyReportPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <MonthlyReportBody />
    </div>
  );
}

export default MonthlyReportPage;
