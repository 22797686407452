import { Button, Grid, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import paginate from 'Common';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';

import { PAGE_SIZE } from '../../../constants';
import { colors } from '../../../constants/colors';
import { HeaderTableHeight } from '../../../containers/ExportBillingBody/constants';
import { TProductDTO } from '../../../DTO';
import { uuid } from '../../../helpers/help';

type Props = {
  data?: TProductDTO[];
  choseProduct: any;
  actionSearch: any;
  resetHighLightLeft: boolean;
};

function ListOfProducts({ data: dataProps = [], choseProduct, actionSearch, ...props }: Props) {
  const [row_, setRow_] = useState<any>(undefined);
  const [data, setData] = useState(dataProps);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');

  const [dataPag, setDataPag] = useState(paginate(data, PAGE_SIZE, page));

  useEffect(() => {
    setData(dataProps);
    setPage(0);
    setDataPag(paginate(dataProps, PAGE_SIZE, page));
  }, [dataProps]);

  useEffect(() => {
    setRow_(undefined);
  }, [props.resetHighLightLeft]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setDataPag(paginate(data, rowsPerPage, newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDataPag(paginate(data, parseInt(event.target.value, 10), 0));
    setPage(0);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const search = () => {
    actionSearch(searchText);
  };

  return (
    <div>
      <div style={{ height: HeaderTableHeight }}>
        <h3>Danh sách sản phẩm cung cấp</h3>
        <div style={{ display: 'flex', gap: 20 }}>
          <Grid item xs={12} sm={4}>
            <InputCustom
              label='Sản phẩm'
              value={searchText}
              handleChange={handleChangeInput}
              keyPress={(ev) => {
                if (ev.key === 'Enter') {
                  actionSearch(searchText);
                }
              }}
            />
          </Grid>
          <Grid>
            <Button
              variant='contained'
              color='primary'
              onClick={search}
              sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 2, sm: 0 } }}
            >
              Tìm kiếm
            </Button>
          </Grid>
        </div>
      </div>
      <TableContainer component={Paper} sx={{ width: { xs: '96vw', sm: 'unset' } }}>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Loại</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Mã SP</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Tên SP
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Quy Cách
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Giá Bán
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPag?.map((row: any, index: any) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  row.count = 0;
                  row.idCustom = uuid();
                  row.idProduct = row.id;
                  choseProduct(row);
                  setRow_(index);
                }}
                style={{ cursor: 'pointer', backgroundColor: index === row_ ? colors.chooseItem : 'transparent' }}
              >
                <TableCell>{row?.category?.name ?? 'Unknown'}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell align='left'>{row.name}</TableCell>
                <TableCell align='left'>{row.specifications}</TableCell>
                <TableCell align='left'>{row.salePrice.toLocaleString('it-IT')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={data?.length}
          component='div'
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default React.memo(ListOfProducts);
