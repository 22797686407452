import { Button, Grid, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { paramsFun } from 'helpers/help';
import DatePickCustom from 'presents/DatePickCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import PreviewPDF from '../../PreviewPDF';

function CarSynthesisHeader(): JSX.Element {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [car, setcar] = React.useState('');
  const [name, setName] = React.useState('');
  const [listCar, setListCar] = React.useState<any[]>([]);
  const [fileLink, setFileLink] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const handleChangeCar = (event: SelectChangeEvent) => {
    const item = listCar.find((x) => x?.id == event.target.value);
    if (item) {
      setName(item.driverName);
    }
    setcar(event.target.value as string);
  };

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const [cars, SetCars] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getListCar();
    if (!result || !result.success) return;
    const a: any[] = [];

    if (result && result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.driverName + ' ✧ ' + element.code });
      });
      SetCars(a);
      setListCar(result.data || []);
    }
  };

  const onDownloadIn = async () => {
    if (!car) return toast('Bạn cần chọn xe để xuất Excel!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/truck/excel?' +
      paramsFun({ date: value?.toString(), truckId: parseInt(car) });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreviewIn = async () => {
    if (!car) return toast('Bạn cần chọn xe để xuất Báo cáo!');
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/truck?' +
      paramsFun({ date: value?.toString(), truckId: parseInt(car) });
    setFilePreview(link);
    setTitle('Bảng tổng hợp nhập hàng: ');
    // window.open(link, '_blank');
  };

  const onDownloadOut = () => {
    if (!car) return toast('Bạn cần chọn xe để xuất Báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/truck-detail/excel?' +
      paramsFun({ date: value?.toString(), truckId: parseInt(car) });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreviewOut = () => {
    if (!car) return toast('Bạn cần chọn xe để xuất Báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/truck-detail?' +
      paramsFun({ date: value?.toString(), truckId: parseInt(car) });
    setFilePreview(link);
    setTitle('Bảng tổng hợp xuất hàng: ');
    // window.open(link, '_blank');
  };

  return (
    <div>
      <h3>Tìm kiếm</h3>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={car} handleChange={handleChangeCar} data={cars} label='Xe' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickCustom label='Ngày' value={value} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Grid container spacing={{ sm: 4, xs: 1 }} style={{ maxWidth: '50%', marginTop: 20 }}>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadIn}>
            Download bảng tổng hợp nhập hàng
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewIn}>
            Xem bảng tổng hợp nhập hàng
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadOut}>
            Download bảng tổng hợp xuất hàng
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewOut}>
            Xem bảng tổng hợp xuất hàng
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title='CarSynthesisHeader' />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default CarSynthesisHeader;
