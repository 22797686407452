import { Button, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { paramsFun } from 'helpers/help';
import DatePickCustom from 'presents/DatePickCustom/Index';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import PreviewPDF from '../../PreviewPDF';

function GoodsSynthesisHeader(): JSX.Element {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [fileLink, setFileLink] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const findAction = async () => {
    const link = process.env.REACT_APP_API + '/api/v1/report/product/excel?' + paramsFun({ date: value?.toString() });
    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreview = async () => {
    const link = process.env.REACT_APP_API + '/api/v1/report/product?' + paramsFun({ date: value?.toString() });
    setFilePreview(link);
    setTitle('Thông tin xuất hàng: ');
    // window.open(link, '_blank');
  };

  return (
    <div>
      <h3>Tìm kiếm</h3>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={3}>
          <DatePickCustom label='Ngày' value={value} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Grid container spacing={{ sm: 4, xs: 1 }} style={{ maxWidth: '50%', marginTop: 20 }}>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={findAction}>
            Export Excel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreview}>
            Export PDF
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default GoodsSynthesisHeader;
