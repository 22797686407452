import UpdateBillBody from 'containers/UpdateBillBody';

import SlideBar from '../../components/SlideBar';

function UpdateBillPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <UpdateBillBody />
    </div>
  );
}

export default UpdateBillPage;
