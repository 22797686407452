import CarSynthesisHeader from 'components/CarSynthesis/CarSynthesisHeader';
import React from 'react';

function CarSynthesisBody(): JSX.Element {
  return (
    <div>
      <CarSynthesisHeader />
    </div>
  );
}

export default CarSynthesisBody;
