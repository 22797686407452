export const AppRoutes = {
  login: '/',
  home: '/home',
  exportBilling: '/home',
  updateBill: '/update_bill',
  carSynthesis: '/car_synthesis',
  typeSynthetic: '/type_synthetic',
  goodsSynthetic: '/goods_synthetic',
  product: '/product',
  productQuotes: '/product-quotes',
  warehouse: '/warehouse',
  proposal: '/proposal',
  monthlyReport: '/monthly_report',
  profit: '/profit',
  debt: '/debt',
  type: '/type',
  car: '/car',
  school: '/school',
  file: '/file_setting',
};
