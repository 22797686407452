import { Grid, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

type Props = {
  schoolChecked?: any;
  setSchool?: any;
};

function TopHeader({ schoolChecked, ...props }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(schoolChecked);
  const [school, setSchool] = useState('');
  const [schools, setSchools] = useState<any[]>([]);
  const [schoolCode, setSchoolCode] = useState<string>('');
  const [car, setCar] = useState('');

  useEffect(() => {
    init();
  }, []);

  const handleChangeSchool = (event: SelectChangeEvent) => {
    const index = schools.map((item) => item.value).indexOf(event.target.value);
    const code = schools[index].code;
    const customerId = schools[index].value;
    setSchool(event.target.value as string);
    setSchoolCode(code);
    setCar(schools[index].car);
    props.setSchool(customerId);
  };

  const init = async () => {
    await getSchool();
  };

  const getSchool = async () => {
    const result: IRestApiResponse = await Services.getAllCustomer();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({
          value: element.id,
          label: element.name,
          existedBill: element.existedBill,
          code: element.code,
          car: element.truck?.driverName + ' ✧ ' + element.truck?.code,
        });
      });
      setSchools(a);
    }
  };

  useEffect(() => {
    setStateBill({ ...schoolChecked, truckId: schoolChecked?.truck?.id });
  }, [schoolChecked]);

  const handleChangeCar = (event: SelectChangeEvent) => {
    setStateBill({ ...stateBill, truckId: event.target.value });
  };

  return (
    <div>
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid item xs={12} sm={2}>
          <InputCustom label='Mã trường' value={schoolCode} disabled />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={school} handleChange={handleChangeSchool} data={schools} label='Tên trường' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom value={car} label='Xe' disabled />
        </Grid>
      </Grid>
    </div>
  );
}

export default TopHeader;
