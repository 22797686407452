import React from 'react';

import FileSettingItem from '../../components/FileSetting/Item';

function SettingExcelBody(): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FileSettingItem />
    </div>
  );
}

export default SettingExcelBody;
