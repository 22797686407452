import { Button, Grid, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

import TopHeader from './Top';

type Props = {
  productEdit?: any;
  actionSave: any;
  actionExport: () => void;
  schoolChecked?: any;
  setSchool?: any;
  actionExportPDF: () => void;
};

function ProductQuotesHeader({ productEdit, actionExport, schoolChecked, actionSave, ...props }: Props): JSX.Element {
  const [stateProduct, setStateProduct] = useState(productEdit);

  useEffect(() => {
    setStateProduct({ ...productEdit, categoryId: productEdit?.category?.id });
  }, [productEdit]);

  const [listCars, setListCar] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    Services.getAllCategory().then((results) => {
      results.data.forEach((element: any) => {
        listCars.push({ value: element.id, label: element.name });
      });
      setListCar(listCars);
    });
  };

  const handleChangeCategory = (event: SelectChangeEvent) => {
    setStateProduct({ ...stateProduct, categoryId: event.target.value });
  };

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateProduct({ ...stateProduct, [idElement]: event.target.value });
  };

  return (
    <div>
      <TopHeader schoolChecked={schoolChecked} setSchool={props.setSchool} />
      <h3>Thông tin sản phẩm</h3>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={{ sm: 4, xs: 1 }}>
            <Grid item xs={12} sm={4}>
              <InputCustom label='Mã sản phẩm' value={stateProduct?.code} disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputCustom label='Tên sản phẩm' value={stateProduct?.name} disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectCustom
                value={stateProduct?.categoryId}
                handleChange={handleChangeCategory}
                data={listCars}
                label='Loại'
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputCustom label='Quy cách' value={stateProduct?.specifications} disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputCustom
                label='Giá bán'
                value={stateProduct?.salePrice.toLocaleString('it-IT')}
                handleChange={(e) => handleChangeInputState(e, 'salePrice')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => actionSave(stateProduct)}
                sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 2, sm: 0 } }}
              >
                Lưu
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <Button variant='contained' fullWidth style={{ height: '100%', margin: 5, top: -40 }} onClick={actionExport}>
            Xuất Báo Giá
          </Button>
          <Button
            variant='contained'
            fullWidth
            style={{ height: '100%', margin: 5, top: -40 }}
            onClick={props.actionExportPDF}
          >
            Xuất Báo Giá (PDF)
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductQuotesHeader;
