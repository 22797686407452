import TypeSynthesisHeader from 'components/TypeSynthesis/TypeSynthesisHeader';
import React from 'react';

function CarSynthesisBody(): JSX.Element {
  return (
    <div>
      <TypeSynthesisHeader />
    </div>
  );
}

export default CarSynthesisBody;
