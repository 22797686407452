import { InputLabel, TextField } from '@mui/material';
import React from 'react';

import { colors } from '../../constants/colors';
import style from './style.module.scss';

type Props = {
  label?: string;
  value?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  keyPress?: (event: any) => void;
  disabled?: boolean;
  w?: number;
};

function InputCustom({ label, value, handleChange, keyPress, disabled, w }: Props): JSX.Element {
  return (
    <div className={style.inputWrap} style={{ minWidth: 100, width: w ? w : undefined }}>
      <InputLabel id='test-select-label' color='primary'>
        <div style={{ color: colors.label, fontWeight: 500 }}>{label}</div>
      </InputLabel>
      <TextField
        onFocus={(event) => {
          event.target.select();
        }}
        id='outlined-basic'
        variant='outlined'
        onChange={handleChange}
        onKeyDown={keyPress}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

export default InputCustom;
