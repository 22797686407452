import CarHeader from 'components/Car/CarHeader';
import CarTable from 'components/Car/CarTable';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

const initialState = {
  code: '',
  type: '',
  driverName: '',
};

function CarBody(): JSX.Element {
  const [typeChose, setCarChose] = useState({ ...initialState });
  const [cars, setCars] = useState();
  const choseCar = (type: any) => {
    setCarChose(type);
  };

  useEffect(() => {
    getCar();
  }, []);

  const handleCarEdit = (car: any, edited: boolean) => {
    setCarChose(initialState);

    if (edited) {
      Services.updateCar(car).then((results) => {
        setCarChose({ ...initialState });
        getCar();
      });
    } else {
      Services.addNewCar(car).then((results) => {
        setCarChose({ ...initialState });
        getCar();
      });
    }
  };

  const getCar = async () => {
    const result: IRestApiResponse = await Services.getListCar();
    if (!result || !result.success) return;
    if (result.success) {
      setCars(result.data);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CarHeader
        typeEdit={typeChose}
        okeAction={function (typeEdit: any, edited = false): void {
          handleCarEdit(typeEdit, edited);
        }}
      />
      {cars && <CarTable data={cars} choseCar={choseCar} />}
    </div>
  );
}

export default CarBody;
