import GoodsSynthesisHeader from 'components/GoodsSynthesis/GoodsSynthesisHeader';
import React from 'react';

function GoodsSyntheticBody(): JSX.Element {
  return (
    <div>
      <GoodsSynthesisHeader />
    </div>
  );
}

export default GoodsSyntheticBody;
