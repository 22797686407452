import { Button, Grid, SelectChangeEvent } from '@mui/material';
import DatePickCustom from 'presents/DatePickCustom/Index';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

type Props = {
  billEdit?: any;
  okeAction: (billEdit: any) => void;
  onEdit: any;
};

function UpdateBillHeader({ billEdit, okeAction, onEdit }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(billEdit);
  const [isEdit, setIsEdit] = useState(false);
  const [schools, setSchools] = useState<any[]>([]);

  useEffect(() => {
    if (billEdit?.id) {
      setIsEdit(true);
    }
    setStateBill(billEdit);
  }, [billEdit]);

  const handleChangeSchool = (event: SelectChangeEvent) => {
    setStateBill({ ...stateBill, school: event.target.value });
    okeAction({ ...stateBill, school: event.target.value });
  };

  const handleChange = (newValue: any) => {
    const day = '$d';
    setStateBill({ ...stateBill, date: newValue[day].toISOString() });
    okeAction({ ...stateBill, date: newValue[day].toISOString() });
  };

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.value });
  };

  const updateOrder = () => {
    onEdit(stateBill);
    reloadPick_();
  };

  const reloadPick_ = () => {
    const a = {
      ...stateBill,
      id: '',
      isDone: '',
      code: '',
      name: '',
      specifications: '',
      price: '',
      quantity: '',
      actuallyReceived: '',
      // additionalQuantity: '',
      idSp: '',
    };
    setIsEdit(false);
    setStateBill(a);
  };

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getAllCustomer();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.name });
      });
      setSchools(a);
    }
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={2}>
          <InputCustom label='Mã Hoá Đơn' value={stateBill?.id} disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectCustom
            value={stateBill?.school}
            handleChange={handleChangeSchool}
            data={schools}
            label='Trường Học'
            // disabled={stateBill?.id}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DatePickCustom label='Ngày' value={stateBill?.date} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputCustom label='Mã sản phẩm' value={stateBill?.code} disabled />
        </Grid>
        {/*
        <Grid item xs={12} sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              okeAction(stateBill);
            }}
            // disabled={isEdit}
          >
            Tìm kiếm
          </Button>
        </Grid>
        */}
        <Grid item xs={12} sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              updateOrder();
            }}
            disabled={!isEdit}
          >
            Sửa
          </Button>
        </Grid>

        <Grid item xs={12} sm={3}>
          <InputCustom label='Tên sản phẩm' value={stateBill?.name} disabled />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputCustom
            label='Thực nhận'
            value={stateBill?.actuallyReceived}
            handleChange={(e) => handleChangeInputState(e, 'actuallyReceived')}
          />
        </Grid>
        {/*<Grid item xs={12} sm={2}>*/}
        {/*  <InputCustom*/}
        {/*    label='Bổ sung'*/}
        {/*    value={stateBill?.additionalQuantity}*/}
        {/*    handleChange={(e) => handleChangeInputState(e, 'additionalQuantity')}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={2}>
          <InputCustom label='Giá bán' value={stateBill?.price?.toLocaleString('it-IT')} disabled />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputCustom label='Quy cách' value={stateBill?.specifications} disabled />
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              okeAction(stateBill);
            }}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default UpdateBillHeader;
