export const dummyFiles = [
  {
    id: 'unit_name',
    label: 'Tên đơn vị',
    placeholder: 'CTCP Nông trại Việt',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'display_name',
    label: 'Tên hiển thị',
    placeholder: 'Phiếu Xuất Kho',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'footer_1',
    label: 'Footer 1',
    placeholder: 'Người lập phiếu',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'footer_2',
    label: 'Footer 2',
    placeholder: 'Người nhận',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'footer_3',
    label: 'Footer 3',
    placeholder: 'Thủ kho',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'footer_4',
    label: 'Footer 4',
    placeholder: 'Kế toán',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
  {
    id: 'footer_5',
    label: 'Footer 5',
    placeholder: '',
    switchEnable: 'Hiển thị',
    switchDisable: 'Ẩn',
  },
];

export const FileType = [
  'BILL',
  'TRUCK_REPORT',
  'TRUCK_REPORT_DETAIL',
  'CATEGORY_REPORT',
  'CATEGORY_REPORT_DETAIL',
  'PRODUCT_REPORT',
  'MONTH_REPORT',
  'MONTH_REPORT_DETAIL',
  'DEBT_REPORT',
  'QUOTATION',
  'PROPOSED',
];
