import TypeHeader from 'components/Type/TypeHeader';
import TypeTable from 'components/Type/TypeTable';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

export const initialStateType = {
  code: '',
  name: '',
  stockAble: true,
  truckReport: true,
  productReport: true,
};

function TypeBody(): JSX.Element {
  const [itemChose, setItemChose] = useState({ ...initialStateType });
  const [data, setData] = useState();

  useEffect(() => {
    getItems();
  }, []);

  const handleItemEdit = (item: any, edited = false) => {
    setItemChose(initialStateType);

    if (edited) {
      Services.updateCategory(item).then((results) => {
        getItems();
      });
    } else {
      Services.addCategory(item).then((results) => {
        getItems();
      });
    }
  };

  const getItems = () => {
    Services.getAllCategory().then((results) => {
      setData(results.data);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TypeHeader typeEdit={itemChose} okeAction={handleItemEdit} />
      {data && <TypeTable data={data} choseType={setItemChose} />}
    </div>
  );
}

export default TypeBody;
