import ProposalBody from 'containers/ProposalBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function ProposalPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <ProposalBody />
    </div>
  );
}

export default ProposalPage;
