import ProductHeader from 'components/Product/ProductHeader';
import ProductTable from 'components/Product/ProductTable';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import PreviewPDF from '../../components/PreviewPDF';

const initialState = {
  categoryId: 0,
  code: '',
  importPrice: 0,
  name: '',
  salePrice: 0,
  specifications: '',
  stockAble: true,
};

interface IState {
  keyword: string;
  code: string;
  customerId: string;
  stockAble: boolean | null;
  name: string;
}

function ProductBody(): JSX.Element {
  const [itemChose, setItemChose] = useState({ ...initialState });
  const [data, setData] = useState();
  const [fileLink, setFileLink] = useState('');
  const [state, setState] = useState<IState>({
    keyword: '',
    code: '',
    customerId: '',
    stockAble: null,
    name: '',
  });
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    getItems();
  }, []);

  const handleItemEdit = (item: any) => {
    if (item.id) {
      Services.updateProduct(item).then((results) => {
        setItemChose(initialState);
        getItems();
      });
    } else {
      Services.addProduct(item).then((results) => {
        setItemChose(initialState);
        getItems();
      });
    }
  };

  const onSearch = async (searchText: string) => {
    setState({ ...state, keyword: searchText });
    getItems(searchText);
  };

  const actionExport = () => {
    const link = process.env.REACT_APP_API + '/api/v1/report/quotation/excel?';

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreview = () => {
    const link = process.env.REACT_APP_API + '/api/v1/report/quotation?';

    setFilePreview(link);
    setTitle('Danh sách sản phẩm: ');
    // window.open(link, '_blank');
  };

  const getItems = (searchText = '') => {
    Services.getAllProduct({ keyword: searchText }).then((results) => {
      setData(results.data);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ProductHeader
        productEdit={itemChose}
        okeAction={handleItemEdit}
        actionExport={actionExport}
        actionPreview={onPreview}
      />
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title='iframe-output-order' />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
      {data && <ProductTable data={data} choseBill={setItemChose} actionSearch={onSearch} />}
    </div>
  );
}

export default ProductBody;
