import ProductQuotesBody from 'containers/ProductQuotesBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function ProductQuotesPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <ProductQuotesBody />
    </div>
  );
}

export default ProductQuotesPage;
