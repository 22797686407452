import React from 'react';

import LoginBody from '../../containers/LoginBody';

function LoginPage(): JSX.Element {
  return (
    <div
      style={{
        alignSelf: 'center',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LoginBody />
    </div>
  );
}

export default LoginPage;
