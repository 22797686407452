import moment from 'moment';

export default function paginate(array: any, page_size: any, page_number: any): any {
  if (!array) return [];
  return array.slice(page_number * page_size, (page_number + 1) * page_size);
}

export const localeStringToNum = (localeString: string) => {
  return parseInt(localeString.replace(/\D/g, ''));
};

export const parseDate = (date: any) =>
  (date?.$d && moment(date?.$d.toISOString()).format('YYYY/MM/DD')) || '2022/01/01';

export const formatDate = (date: any) => (date && moment(date.toISOString()).format('YYYY/MM/DD')) || '2022/02/01';
