import BillTable from 'components/UpdateBill/BillTable';
import Footer from 'components/UpdateBill/Footer';
import UpdateBillHeader from 'components/UpdateBill/UpdateBillHeader';
import { calculatorTotalPrice } from 'helpers/calculatorTotalPrice';
import { paramsFun } from 'helpers/help';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { TFlatOrderDTO, TOrderDTO } from '../../DTO';
import { Services } from '../../services';

export const initFlatOrder: TFlatOrderDTO = {
  index: 0,
  id: 0,
  code: '',
  date: '',
  customer: {
    id: 0,
    code: '',
    name: '',
  },
  isDone: false,
  detail: {
    id: 0,
    productItem: {
      id: 0,
      code: '',
      name: '',
    },
    price: 0,
    quantity: 0,
    actuallyReceived: 0,
    // additionalQuantity: 0,
  },
};

const initOrder = {
  id: 0,
  code: '',
  date: '',
  customer: {
    id: 0,
    code: '',
    name: '',
  },
  isDone: false,
  detail: [],
};

function UpdateBillBody(): JSX.Element {
  const [orderList, setOrderList] = useState<TFlatOrderDTO[]>([initFlatOrder]);
  const [billChose, setBillChose] = useState<any>();
  const [fileLink, setFileLink] = useState('');
  const [totalMoney, setTotalMoney] = useState(0);
  const [searchAction, setSearchAction] = useState<any>();
  const [dataUpdate, setDataUpdate] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(0);

  const choseBill = (bill: any, b: number) => {
    const billTemp = bill;
    setBillChose(billTemp);
    setIndex(b);
  };

  const onClickOk = async (bill: any) => {
    const parseDate = moment(bill?.date).format('YYYY/MM/DD') || '2022/10/14';
    setSearchAction({ date: parseDate, customerId: bill.school });
    const result = await Services.getDetailOrder({ date: parseDate, customerId: bill.school });
    if (result.success && result.data !== null) {
      const billTemp = result.data;
      const detailBill: TFlatOrderDTO[] = [];
      const dataItem = {
        id: billTemp?.id,
        date: billTemp?.date,
        school: billTemp?.customer?.id,
        isDone: billTemp?.isDone,
      };
      for (let i = 0; i < billTemp.detail.length; i++) {
        const flatBill = {
          index: i,
          ...dataItem,
          ...{
            code: billTemp.detail[i].productItem?.code,
            name: billTemp.detail[i].productItem?.name,
            specifications: billTemp.detail[i].productItem?.specifications,
            price: billTemp.detail[i].price,
            quantity: billTemp.detail[i].quantity,
            actuallyReceived: billTemp.detail[i].actuallyReceived,
            // additionalQuantity: billTemp.detail[i].additionalQuantity,
            idSp: billTemp.detail[i].id,
          },
        };
        detailBill.push(flatBill as any);
      }

      let totalMoneyTmp = 0;
      detailBill.forEach((item: any) => {
        totalMoneyTmp += calculatorTotalPrice({
          price: item?.price ?? 0,
          quantity: item?.quantity ?? 0,
          actual: item?.actuallyReceived ?? 0,
          isDone: item?.isDone,
        });
      });
      setTotalMoney(totalMoneyTmp);
      setOrderList(detailBill);
    } else {
      setTotalMoney(0);
      setOrderList([initFlatOrder]);
    }
  };

  const findAction = async () => {
    const link =
      process.env.REACT_APP_API +
      '/api/v1/delivery/export?' +
      paramsFun({ date: searchAction?.date.toString(), customerId: searchAction?.customerId });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onEdit = async (stateBill: any) => {
    const data = {
      actuallyReceived: parseFloat(stateBill?.actuallyReceived),
      // additionalQuantity: parseFloat(stateBill?.additionalQuantity),
      itemsId: stateBill?.idSp,
    };

    const order = {
      ...orderList[index],
      actuallyReceived: parseFloat(stateBill?.actuallyReceived),
      // additionalQuantity: parseFloat(stateBill?.additionalQuantity),
    };
    const newList = orderList;
    newList[index] = order;

    setDataUpdate([...dataUpdate, data]);
    setOrderList([...newList]);
    const params = {
      id: billChose?.id,
      detail: [data],
    };
    await Services.updateOrder(params as any).then((result) => {
      onClickOk(billChose);
      if (result.error) toast(result?.error?.message);
    });
  };

  const updateBill = async () => {
    const params = {
      id: billChose?.id,
      detail: dataUpdate,
    };
    await Services.updateOrder(params as any).then((result) => {
      onClickOk(billChose);
      if (result.error) toast(result?.error?.message);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <UpdateBillHeader billEdit={billChose} okeAction={onClickOk} onEdit={onEdit} />
      <BillTable data={orderList} choseBill={choseBill} />
      <Footer total={totalMoney} actionExport={findAction} disabled={!searchAction} actionUpdate={updateBill} />
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
    </div>
  );
}

export default UpdateBillBody;
