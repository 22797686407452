import { Button, Grid, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';

type Props = {
  typeEdit?: any;
  okeAction: (typeEdit: any, b: boolean) => void;
};

function CarHeader({ typeEdit, okeAction }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(typeEdit);
  useEffect(() => {
    setStateBill(typeEdit);
  }, [typeEdit]);

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.value });
  };

  return (
    <div>
      <h3>Thêm sửa xe</h3>
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid item xs={12} sm={4}>
          <InputCustom
            label='Biển số xe'
            value={stateBill?.code}
            handleChange={(e) => handleChangeInputState(e, 'code')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputCustom
            label='Loại xe'
            value={stateBill?.type}
            handleChange={(e) => handleChangeInputState(e, 'type')}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputCustom
            label='Lái xe'
            value={stateBill?.driverName}
            handleChange={(e) => handleChangeInputState(e, 'driverName')}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              // disabled={stateBill.id}
              onClick={() => {
                okeAction(stateBill, false);
              }}
            >
              Thêm
            </Button>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              disabled={!stateBill.id}
              onClick={() => {
                okeAction(stateBill, true);
              }}
            >
              Sửa
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default CarHeader;
