import MonthlyReportHeader from 'components/MonthlyReport/MonthlyReportHeader';
import React, { useState } from 'react';

import MonthlySynthesisTable from '../../components/MonthlyReport/Table';
import { TMonthlyDetailReport, TMonthlyItemsReport } from '../../DTO';

function MonthlyReportBody(): JSX.Element {
  const [data, setData] = useState<TMonthlyItemsReport[]>([]);
  const [detailData, setDetailData] = useState<TMonthlyDetailReport[]>([]);

  return (
    <div>
      <MonthlyReportHeader setData={setData} setDetailData={setDetailData} />
      {/*<MonthlySynthesisTable listBill={data} listDetail={detailData} />*/}
    </div>
  );
}

export default MonthlyReportBody;
