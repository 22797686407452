import 'react-toastify/dist/ReactToastify.css';

import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SlideBar from 'components/SlideBar';
import DebtPage from 'pages/DebtPage';
import ProfitPage from 'pages/ProfitPage';
import ProposalPage from 'pages/ProposalPage';
import SchoolPage from 'pages/SchoolPage';
import WarehousePage from 'pages/WarehousePage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppRoutes } from '../helpers/app.routes';
import MainLayout from '../layouts/MainLayout';
import CarPage from '../pages/CarPage/index';
import CarSynthesis from '../pages/CarSynthesisPage/index';
import ExportBillingPage from '../pages/ExportBillingPage/index';
import GoodsSynthetic from '../pages/GoodsSyntheticPage/index';
import LoginPage from '../pages/LoginPage';
import MonthlyReportPage from '../pages/MonthlyReportPage/index';
import ProductPage from '../pages/ProductPage/index';
import ProductQuotesPage from '../pages/ProductQuotesPage/index';
import SettingExcelPage from '../pages/SettingExcelPage';
import TypePage from '../pages/TypePage/index';
import TypeSynthetic from '../pages/TypeSyntheticPage/index';
import UpdateBillPage from '../pages/UpdateBillPage/index';

// Create a client
const queryClient = new QueryClient();

export default function DoctorRoutes(): JSX.Element {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MainLayout
            content={
              <div
                style={{
                  flex: 1,
                  height: '100vh',
                  display: 'flex',
                }}
              >
                <SlideBar />
                <Box style={{ flex: 1 }} sx={{ mt: { xs: 1, sm: 0 }, p: { sm: 2, xs: 1 } }}>
                  <Routes>
                    <Route path={AppRoutes.login} element={<LoginPage />} />
                    <Route path={AppRoutes.exportBilling} element={<ExportBillingPage />} />
                    <Route path={AppRoutes.updateBill} element={<UpdateBillPage />} />
                    <Route path={AppRoutes.carSynthesis} element={<CarSynthesis />} />
                    <Route path={AppRoutes.typeSynthetic} element={<TypeSynthetic />} />
                    <Route path={AppRoutes.goodsSynthetic} element={<GoodsSynthetic />} />
                    <Route path={AppRoutes.product} element={<ProductPage />} />
                    <Route path={AppRoutes.productQuotes} element={<ProductQuotesPage />} />
                    <Route path={AppRoutes.warehouse} element={<WarehousePage />} />
                    <Route path={AppRoutes.proposal} element={<ProposalPage />} />
                    <Route path={AppRoutes.monthlyReport} element={<MonthlyReportPage />} />
                    <Route path={AppRoutes.profit} element={<ProfitPage />} />
                    <Route path={AppRoutes.debt} element={<DebtPage />} />
                    <Route path={AppRoutes.type} element={<TypePage />} />
                    <Route path={AppRoutes.car} element={<CarPage />} />
                    <Route path={AppRoutes.school} element={<SchoolPage />} />
                    <Route path={AppRoutes.file} element={<SettingExcelPage />} />
                  </Routes>
                </Box>
              </div>
            }
          />
        </LocalizationProvider>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
}
