import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { getMonths, getYear } from 'constants/date';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import { paramsFun } from '../../../helpers/help';
import PreviewPDF from '../../PreviewPDF';

function DebtHeader(): JSX.Element {
  const [month, setMonth] = React.useState(new Date().getMonth().toString());
  const [year, setYear] = React.useState(new Date().getFullYear().toString());
  const [fileLink, setFileLink] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const handleSetMonth = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  };
  const handleSetYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const [months, setMonths] = useState<any[]>([]);
  const [years, setYears] = useState<any[]>([]);
  const [type, setType] = React.useState('');
  const [categorys, SetCategorys] = useState<any[]>([]);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getAllCategory();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.name });
      });
      SetCategorys(a);
    }
  };

  useEffect(() => {
    setMonths(getMonths());
    setYears(getYear());
    getItems();
  }, []);

  const onDownloadByProduct = () => {
    if (!type) return toast('Hãy chọn loại hàng hóa để xem báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/partner-debt/excel?' +
      paramsFun({
        categoryId: type,
        month,
        year,
      });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreviewByProduct = () => {
    if (!type) return toast('Hãy chọn loại hàng hóa để xem báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/partner-debt?' +
      paramsFun({
        categoryId: type,
        month,
        year,
      });
    setFilePreview(link);
    setTitle('Báo cáo công nợ theo sản phẩm: ');
    // window.open(link, '_blank');
  };

  const onDownloadByDay = () => {
    if (!type) return toast('Hãy chọn loại hàng hóa để xem báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/partner-debt-detail/excel?' +
      paramsFun({
        categoryId: type,
        month,
        year,
      });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreviewByDay = () => {
    if (!type) return toast('Hãy chọn loại hàng hóa để xem báo cáo!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/partner-debt-detail?' +
      paramsFun({
        categoryId: type,
        month,
        year,
      });
    setFilePreview(link);
    setTitle('Báo cáo công nợ theo ngày: ');
    // window.open(link, '_blank');
  };

  return (
    <div>
      <h3>Tìm kiếm</h3>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={5}>
          <SelectCustom value={type} handleChange={handleChangeType} data={categorys} label='Loại' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={month} handleChange={handleSetMonth} data={months} label='Tháng' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={year} handleChange={handleSetYear} data={years} label='Năm' />
        </Grid>
      </Grid>
      <Grid container spacing={{ sm: 4, xs: 1 }} style={{ maxWidth: '50%', marginTop: 20 }}>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadByProduct}>
            Download công nợ theo sản phẩm
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewByProduct}>
            Xem công nợ theo sản phẩm
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadByDay}>
            Download công nợ theo ngày
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewByDay}>
            Xem công nợ theo ngày
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default DebtHeader;
