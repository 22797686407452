import WarehouseBody from 'containers/WarehouseBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function WarehousePage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <WarehouseBody />
    </div>
  );
}

export default WarehousePage;
