import ProductQuotesHeader from 'components/ProductQuotes/ProductQuotesHeader';
import ProductQuotesTable from 'components/ProductQuotes/ProductQuotesTable';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import { localeStringToNum } from '../../Common';
import PreviewPDF from '../../components/PreviewPDF';
import { paramsFun } from '../../helpers/help';

const initialState = {
  categoryId: 0,
  code: '',
  importPrice: 0,
  name: '',
  salePrice: 0,
  specifications: '',
  stockAble: true,
};

interface IState {
  keyword: string;
  code: string;
  customerId: string;
  stockAble: boolean | null;
  name: string;
}

function ProductQuotesBody(): JSX.Element {
  const [itemChose, setItemChose] = useState({ ...initialState });
  const [data, setData] = useState();
  const [fileLink, setFileLink] = useState('');
  const [school, setSchool] = useState('');
  const [state, setState] = useState<IState>({
    keyword: '',
    code: '',
    customerId: '',
    stockAble: null,
    name: '',
  });
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const handleSave = (item: any) => {
    const params: IUpdateProductQuotesParams = {
      productId: item.id,
      customerId: school,
      price: localeStringToNum(item.salePrice),
    };
    Services.updateProductQuotes(params).then(() => {
      setItemChose(initialState);
      getItems();
    });
  };

  const onExport = () => {
    const { code, customerId, keyword, name } = state;
    const link =
      process.env.REACT_APP_API + '/api/v1/report/quotation/excel?' + paramsFun({ code, customerId, keyword, name });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onExportPDF = () => {
    const { code, customerId, keyword, name } = state;
    const link =
      process.env.REACT_APP_API + '/api/v1/report/quotation?' + paramsFun({ code, customerId, keyword, name });
    setFilePreview(link);
    setTitle('Báo giá: ');
    // window.open(link, '_blank');
  };

  const onSearch = async (searchText: string) => {
    setState({ ...state, keyword: searchText });
    getItems(searchText);
  };

  const getItems = (searchText = '') => {
    if (school) {
      Services.getAllProduct({ customerId: school, keyword: searchText }).then((results) => {
        setData(results.data);
      });
    }
  };

  const onChangeSchool = (value: string) => {
    setSchool(value);
    setState({ ...state, customerId: value });
    Services.getAllProduct({ customerId: value }).then((results) => {
      setData(results.data);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ProductQuotesHeader
        productEdit={itemChose}
        actionSave={handleSave}
        actionExport={onExport}
        setSchool={onChangeSchool}
        actionExportPDF={onExportPDF}
      />
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title='iframe-output-order' />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
      {data && <ProductQuotesTable data={data} choseBill={setItemChose} actionSearch={onSearch} />}
    </div>
  );
}

export default ProductQuotesBody;
