import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

import { colors } from '../../constants/colors';
import style from './style.module.scss';

type Props = {
  label?: string;
  value?: string;
  handleChange: (event: SelectChangeEvent) => void;
  data?: any;
  disabled?: any;
};

function SelectCustom({ label, value, handleChange, data, disabled = false }: Props): JSX.Element {
  return (
    <div className={style.selectWrap}>
      <InputLabel id='test-select-label'>
        <div style={{ color: colors.label, fontWeight: 500 }}>{label}</div>
      </InputLabel>
      <Select value={value} onChange={handleChange} variant='outlined' disabled={disabled}>
        {data?.map((item: any, index: number) => (
          <MenuItem value={item.value} key={item.value}>
            <div style={{ color: item.existedBill ? colors.hadBill : colors.notHadBill }}>{item.label}</div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SelectCustom;
