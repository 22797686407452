import { EmptyAvatar } from 'assets/icons';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  fallbackImg?: string;
  src: string;
  className?: string;
  alt?: string;
  style?: any;
}

const HandledImage = ({ src, fallbackImg, alt, className, style }: Props): JSX.Element => {
  const [tried, setTried] = useState(false);
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src);
    return () => {
      setTried(false);
      setImageSrc('');
    };
  }, [src]);

  const handleError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (tried) {
        setImageSrc(fallbackImg || EmptyAvatar);
      } else {
        setImageSrc(`${process.env.REACT_APP_FILE_API}${imageSrc}`);
        setTried(true);
      }
    },
    [fallbackImg, imageSrc, tried]
  );

  return <img src={imageSrc} className={className} onError={handleError} alt={alt} style={style} />;
};

export default React.memo(HandledImage);
