import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import paginate from 'Common';
import React, { useEffect, useState } from 'react';

import { PAGE_SIZE } from '../../../constants';
import { colors } from '../../../constants/colors';
import { TFlatOrderDTO } from '../../../DTO';
import { calculatorTotalPrice } from '../../../helpers/calculatorTotalPrice';
import { uuid } from '../../../helpers/help';

type Props = {
  data: TFlatOrderDTO[];
  choseBill: (bill: any, b: number) => void;
};

function BillTable({ data, choseBill }: Props): JSX.Element {
  const [row_, setRow_] = useState<any>(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataPag, setDataPag] = useState(paginate(data, PAGE_SIZE, page));

  useEffect(() => {
    setDataPag(paginate(data, PAGE_SIZE, 0));
  }, [data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setDataPag(paginate(data, rowsPerPage, newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDataPag(paginate(data, parseInt(event.target.value, 10), 0));
    setPage(0);
  };

  return (
    <div style={{ marginBottom: 40, marginTop: 20 }}>
      <h3>Thông tin hoá đơn</h3>
      <TableContainer component={Paper} sx={{ width: { xs: '96vw', sm: 'unset' } }}>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Mã SP
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Tên SP
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Giá
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Quy cách
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Số lượng
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Thực nhận
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Thành tiền
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPag &&
              dataPag?.map((row: any, index: number) => (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    ':hover': { backgroundColor: '#EFEFEF' },
                  }}
                  onClick={() => {
                    choseBill(row, index);
                    setRow_(index);
                  }}
                  key={uuid()}
                  style={{ cursor: 'pointer', backgroundColor: index === row_ ? colors.chooseItem : 'transparent' }}
                >
                  <TableCell align='left'>{row?.code}</TableCell>
                  <TableCell align='left'>{row?.name}</TableCell>
                  <TableCell align='left'>{row?.price?.toLocaleString('it-IT')}</TableCell>
                  <TableCell align='left'>{row?.specifications}</TableCell>
                  <TableCell align='left'>{row?.quantity}</TableCell>
                  <TableCell align='left'>{row?.actuallyReceived}</TableCell>
                  <TableCell align='left'>
                    {calculatorTotalPrice({
                      price: row.price ?? 0,
                      quantity: row.quantity ?? 0,
                      actual: row.actuallyReceived ?? 0,
                      isDone: row.isDone,
                    }).toLocaleString('it-IT')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          count={data?.length}
          component='div'
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default BillTable;
