import { Button, Checkbox, Grid, SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import { useEffect, useState } from 'react';
import { Services } from 'services';

import { localeStringToNum } from '../../../Common';

type Props = {
  productEdit?: any;
  okeAction: (productEdit: any) => void;
  actionExport: () => void;
  actionPreview: () => void;
};

function ProductHeader({ productEdit, okeAction, actionExport, actionPreview }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(productEdit);

  useEffect(() => {
    setStateBill({ ...productEdit, categoryId: productEdit?.category?.id });
  }, [productEdit]);

  const [listCars, setListCar] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = () => {
    Services.getAllCategory().then((results) => {
      results.data.forEach((element: any) => {
        listCars.push({ value: element.id, label: element.name });
      });
      setListCar(listCars);
    });
  };

  const handleChangeSchool = (event: SelectChangeEvent) => {
    setStateBill({ ...stateBill, categoryId: event.target.value });
  };
  const handleChange = (newValue: Dayjs | null) => {
    setStateBill({ ...stateBill, date: newValue });
  };
  const handleChangeInputState = (event: any, idElement: string) => {
    let value = event.target.value;
    if (idElement === 'importPrice' || idElement === 'salePrice') {
      value = localeStringToNum(event.target.value);
    }
    setStateBill({ ...stateBill, [idElement]: value });
  };
  const handleChangeCheckState = (event: any, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.checked });
  };

  return (
    <div>
      <h3>Thêm sửa sản phẩm</h3>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={{ sm: 4, xs: 1 }}>
            <Grid item xs={12} sm={4}>
              <InputCustom
                label='Mã sản phẩm'
                value={stateBill?.code}
                handleChange={(e) => handleChangeInputState(e, 'code')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputCustom
                label='Tên sản phẩm'
                value={stateBill?.name}
                handleChange={(e) => handleChangeInputState(e, 'name')}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Checkbox checked={stateBill?.stockAble} onChange={(e) => handleChangeCheckState(e, 'stockAble')} /> Lưu
              kho
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputCustom
                label='Quy cách'
                value={stateBill?.specifications}
                handleChange={(e) => handleChangeInputState(e, 'specifications')}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <InputCustom
                label='Giá nhập'
                value={stateBill?.importPrice.toLocaleString('it-IT')}
                handleChange={(e) => handleChangeInputState(e, 'importPrice')}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputCustom
                label='Giá bán'
                value={stateBill?.salePrice.toLocaleString('it-IT')}
                handleChange={(e) => handleChangeInputState(e, 'salePrice')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectCustom
                value={stateBill?.categoryId}
                handleChange={handleChangeSchool}
                data={listCars}
                label='Loại'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
          <Grid container spacing={{ sm: 4, xs: 1 }}>
            <Grid item xs={12} sm={12}>
              <Button
                variant='contained'
                style={{ paddingLeft: 30, paddingRight: 30 }}
                onClick={() => {
                  okeAction(stateBill);
                }}
              >
                Thêm
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button
                variant='contained'
                style={{ paddingLeft: 30, paddingRight: 30 }}
                disabled={!stateBill.id}
                onClick={() => {
                  okeAction(stateBill);
                }}
              >
                Sửa
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={{ sm: 4, xs: 1 }}>
            <Grid item xs={12} sm={12}>
              <Button variant='contained' fullWidth style={{ height: '100%' }} onClick={actionExport}>
                Xuất Danh Sách SP
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button variant='contained' fullWidth style={{ height: '100%' }} onClick={actionPreview}>
                Xuất Danh Sách SP PDF
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProductHeader;
