import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, CssBaseline, Drawer, IconButton, Toolbar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AppRoutes } from 'helpers/app.routes';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { drawerWidth, numberOfRoute } from '../../routes/constants';
import { Services } from '../../services';
import style from './style.module.scss';

const SlideBar = (): JSX.Element | null => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;
  const location = useLocation();

  const logout = async () => {
    await Services.logout();
    navigate(AppRoutes.login);
  };

  const drawer = (
    <div className={style.slideBarWrap}>
      <List>
        {[
          { label: 'Hoá đơn xuất', url: AppRoutes.exportBilling },
          // { label: 'Cập nhật hoá đơn', url: AppRoutes.updateBill },
          { label: 'Tổng hợp xe', url: AppRoutes.carSynthesis },
          { label: 'Tổng hợp loại', url: AppRoutes.typeSynthetic },
          { label: 'Tổng hợp hàng', url: AppRoutes.goodsSynthetic },
          { label: 'Sản phẩm', url: AppRoutes.product },
          { label: 'Báo giá', url: AppRoutes.productQuotes },
          { label: 'Nhập kho', url: AppRoutes.warehouse },
          { label: 'Đề xuất nhập', url: AppRoutes.proposal },
          { label: 'Báo cáo tháng', url: AppRoutes.monthlyReport },
          { label: 'Lợi nhuận', url: AppRoutes.profit },
          { label: 'Công nợ', url: AppRoutes.debt },
          { label: 'Loại', url: AppRoutes.type },
          { label: 'Xe', url: AppRoutes.car },
          { label: 'Trường Học', url: AppRoutes.school },
          { label: 'Cài đặt file', url: AppRoutes.file },
          { label: 'Đăng xuất', url: AppRoutes.login },
        ].map((text, index) => (
          <ListItem
            key={text.label}
            disablePadding
            style={{
              backgroundColor: window.location.pathname === text.url ? '#251B37' : '',
              marginTop: index !== numberOfRoute ? 0 : 20,
            }}
            onClick={() => {
              return index !== numberOfRoute ? navigate(text.url) : logout();
            }}
          >
            <ListItemButton>
              <ListItemText primary={text.label} style={{ color: '#FFFFFF' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  if (location.pathname === '/') {
    return null;
  }

  return (
    <div>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: 'none', xs: 'block' },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
};

export default SlideBar;
