import { InputLabel, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React from 'react';

import { colors } from '../../constants/colors';
import style from './style.module.scss';

type Props = {
  label?: string;
  value?: Dayjs | null;
  handleChange: (newValue: Dayjs | null) => void;
  disabled?: boolean;
};

function DatePickCustom({ label, value, handleChange, disabled = false }: Props): JSX.Element {
  return (
    <div className={style.inputWrap}>
      <InputLabel id='test-select-label'>
        <div style={{ color: colors.label, fontWeight: 500 }}>{label}</div>
      </InputLabel>
      <DesktopDatePicker
        disabled={disabled}
        inputFormat='DD/MM/YYYY'
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </div>
  );
}

export default DatePickCustom;
