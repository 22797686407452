import { Button, Checkbox, Grid, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

type Props = {
  typeEdit?: any;
  okeAction: (typeEdit: any, b: boolean) => void;
};

function SchoolHeader({ typeEdit, okeAction }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(typeEdit);
  const [listCars, setListCar] = useState<any[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getListCar();
    if (!result || !result.success) return;
    const a: any[] = [];

    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.driverName + ' ✧ ' + element.code });
      });
      setListCar(a);
    }
  };

  useEffect(() => {
    setStateBill({ ...typeEdit, truckId: typeEdit?.truck?.id });
  }, [typeEdit]);

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.value });
  };

  const handleChangeCar = (event: SelectChangeEvent) => {
    setStateBill({ ...stateBill, truckId: event.target.value });
  };
  const handleChangeCheckState = (event: any, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.checked });
  };

  return (
    <div>
      <h3>Thêm sửa trường học</h3>
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid item xs={12} sm={2}>
          <InputCustom
            label='Mã trường'
            value={stateBill?.code}
            handleChange={(e) => handleChangeInputState(e, 'code')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom
            label='Tên trường'
            value={stateBill?.name}
            handleChange={(e) => handleChangeInputState(e, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom
            label='Số điện thoại'
            value={stateBill?.phoneNumber}
            handleChange={(e) => handleChangeInputState(e, 'phoneNumber')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom
            label='Địa chỉ'
            value={stateBill?.address}
            handleChange={(e) => handleChangeInputState(e, 'address')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom
            label='Email'
            value={stateBill?.email}
            handleChange={(e) => handleChangeInputState(e, 'email')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={stateBill?.truckId} handleChange={handleChangeCar} data={listCars} label='Xe' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputCustom
            label='Huyện'
            value={stateBill?.district}
            handleChange={(e) => handleChangeInputState(e, 'district')}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Checkbox checked={stateBill?.status} onChange={(e) => handleChangeCheckState(e, 'status')} /> Hoạt động
        </Grid>
        <Grid item xs={12} sm={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              // disabled={stateBill.id}
              onClick={() => {
                okeAction(stateBill, false);
              }}
            >
              Thêm
            </Button>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              disabled={!stateBill.id}
              onClick={() => {
                okeAction(stateBill, true);
              }}
            >
              Sửa
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SchoolHeader;
