import SlideBar from '../../components/SlideBar';
import SettingExcelBody from '../../containers/SettingExcelBody';

function SettingExcelPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <SettingExcelBody />
    </div>
  );
}

export default SettingExcelPage;
