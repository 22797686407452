import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { calculatorTotalPrice } from 'helpers/calculatorTotalPrice';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { HeaderTableHeight } from '../../../containers/ExportBillingBody/constants';

type Props = {
  product?: any;
  actionAdd: (data: any) => void;
  actionEdit: (data: any) => void;
  actionDelete: (data: any) => void;
  isProductInBill?: boolean;
};

function EditProduct({ product, actionAdd, actionEdit, actionDelete, ...props }: Props): JSX.Element {
  const [stateProduct, setStateProduct] = useState(product);

  useEffect(() => {
    setStateProduct(product);
  }, [product]);

  const handleChangeState = (event: SelectChangeEvent, idElement: string) => {
    const numberEnter = event.target.value;
    if (idElement === 'count') {
      // số lượng
      setStateProduct({
        ...stateProduct,
        count: numberEnter,
        actuallyReceived: numberEnter,
        totalPrice: calculatorTotalPrice({
          price: stateProduct?.salePrice ?? 0,
          quantity: parseFloat(numberEnter) ?? 0,
          isDone: false,
          actual: 0,
        }),
      });
    } else {
      // thực nhận
      setStateProduct({
        ...stateProduct,
        actuallyReceived: numberEnter,
        totalPrice: calculatorTotalPrice({
          price: stateProduct?.salePrice ?? 0,
          quantity: parseFloat(numberEnter) ?? 0,
          isDone: false,
          actual: 0,
        }),
      });
    }
  };

  return (
    <div style={{ height: HeaderTableHeight }}>
      <h3>Thông tin hoá đơn</h3>
      <div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Grid container gap={10}>
            <Grid item xs={12} sm={2}>
              <InputCustom
                label='Loại'
                value={stateProduct?.category?.name || stateProduct?.categoryName || ''}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputCustom label='Mã SP' value={stateProduct?.code ?? ''} disabled />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputCustom label='Tên SP' value={stateProduct?.name ?? ''} disabled />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputCustom label='Giá' value={stateProduct?.salePrice ?? ''} disabled />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex' }}>
          <Grid container gap={5}>
            <Grid item xs={12} sm={2}>
              <InputCustom
                label='Số lượng'
                value={stateProduct?.count}
                handleChange={(e) => handleChangeState(e, 'count')}
                keyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    if (!stateProduct?.id) return toast('Bạn cần chọn sản phẩm!');
                    if (!stateProduct?.count) return toast('Bạn cần nhập số lượng!');
                    if (props.isProductInBill) {
                      actionEdit(stateProduct);
                    } else {
                      actionAdd(stateProduct);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputCustom
                label='Thực nhận'
                value={stateProduct?.actuallyReceived ?? 0}
                handleChange={(e) => handleChangeState(e, 'actuallyReceived')}
                keyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    if (!stateProduct?.id) return toast('Bạn cần chọn sản phẩm!');
                    if (!stateProduct?.count) return toast('Bạn cần nhập số lượng!');
                    if (props.isProductInBill) {
                      actionEdit(stateProduct);
                    } else {
                      actionAdd(stateProduct);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <InputCustom label='Quy Cách' value={stateProduct?.specifications} disabled />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  if (!stateProduct?.id) return toast('Bạn cần chọn sản phẩm!');
                  if (!stateProduct?.count) return toast('Bạn cần nhập số lượng!');
                  actionAdd(stateProduct);
                }}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                disabled={props.isProductInBill || parseFloat(stateProduct?.count) <= 0}
              >
                Thêm
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  actionEdit(stateProduct);
                }}
                sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 2, sm: 0 } }}
                disabled={!props.isProductInBill}
              >
                Sửa
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  actionDelete(stateProduct);
                }}
                sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 2, sm: 0 } }}
                disabled={!props.isProductInBill}
              >
                Xoá
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
