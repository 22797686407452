import DebtHeader from 'components/Debt/DebtHeader';
import React from 'react';

function DebtBody(): JSX.Element {
  return (
    <div>
      <DebtHeader />
    </div>
  );
}

export default DebtBody;
