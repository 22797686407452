import { Button, Grid, SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { paramsFun } from 'helpers/help';
import DatePickCustom from 'presents/DatePickCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Services } from 'services';

import PreviewPDF from '../../PreviewPDF';

function TypeSynthesisHeader(): JSX.Element {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const [type, setType] = React.useState('');
  const [categorys, SetCategorys] = useState<any[]>([]);
  const [fileLink, setFileLink] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getAllCategory();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.name });
      });
      SetCategorys(a);
    }
  };

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const onDownloadSuggest = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất Excel!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category/excel?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type) });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onDownloadSuggestDetail = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất Excel!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category-detail/excel?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type) });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onDownloadSuggestDetailHasName = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất Excel!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category-detail/excel?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type), hasName: true });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const onPreviewSuggest = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất PDF!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type) });
    setFilePreview(link);
    setTitle('Bảng đề xuất nhập hàng: ');
    // window.open(link, '_blank');
  };

  const onPreviewSuggestDetail = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất PDF!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category-detail?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type) });
    setFilePreview(link);
    setTitle('Bảng đề xuất nhập hàng chi tiết: ');
    // window.open(link, '_blank');
  };

  const onPreviewSuggestDetailHasName = () => {
    if (!type) return toast('Bạn cần nhập loại để xuất PDF!');

    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/category-detail?' +
      paramsFun({ date: value?.toString(), categoryId: parseFloat(type), hasName: true });
    setFilePreview(link);
    setTitle('Bảng đề xuất nhập hàng chi tiết (có tên khách hàng): ');
    // window.open(link, '_blank');
  };

  return (
    <div>
      <h3>Tìm kiếm</h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={type} handleChange={handleChangeType} data={categorys} label='Loại' />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePickCustom label='Ngày' value={value} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Grid container spacing={{ sm: 4, xs: 1 }} style={{ maxWidth: '50%', marginTop: 20 }}>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadSuggest}>
            Download bảng đề xuất nhập hàng
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewSuggest}>
            Xem bảng đề xuất nhập hàng
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadSuggestDetail}>
            Download bảng đề xuất nhập hàng chi tiết
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewSuggestDetail}>
            Xem bảng đề xuất nhập hàng chi tiết
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onDownloadSuggestDetailHasName}>
            Download bảng đề xuất nhập hàng chi tiết (có tên KH)
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant='contained' onClick={onPreviewSuggestDetailHasName}>
            Xem bảng đề xuất nhập hàng chi tiết (có tên KH)
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default TypeSynthesisHeader;
