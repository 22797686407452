import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';

import style from './style.module.scss';

type Props = {
  label?: string;
  handleChange?: any;
  disabled?: boolean;
  checked: boolean;
};

function SwitchCustom({ label, handleChange, disabled, checked }: Props): JSX.Element {
  return (
    <div className={style.inputWrap} style={{ minWidth: 100 }}>
      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={<Switch defaultChecked onChange={handleChange} checked={checked} />}
          label={label}
        />
      </FormGroup>
    </div>
  );
}

export default SwitchCustom;
