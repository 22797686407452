import { Button, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import SwitchCustom from 'presents/SwitchCustom/Index';
import React, { useEffect, useState } from 'react';

import { dummyFiles, FileType } from '../../../containers/SettingExcelBody/dummy';
import { Services } from '../../../services';

interface IData {
  id: string;
  label?: string;
  placeholder?: string;
  switchEnable?: string;
  switchDisable?: string;
}

function FileSettingItem(): JSX.Element {
  const [file, setFile] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [id, setId] = useState<number>(0);
  const [unit_name, setUnit_name] = useState<boolean>(true);
  const [display_name, setDisplay_name] = useState<boolean>(true);
  const [footer_1, setFooter_1] = useState<boolean>(true);
  const [footer_2, setFooter_2] = useState<boolean>(true);
  const [footer_3, setFooter_3] = useState<boolean>(true);
  const [footer_4, setFooter_4] = useState<boolean>(true);
  const [footer_5, setFooter_5] = useState<boolean>(true);
  const [unit_name_label, setUnit_name_label] = useState<string>('');
  const [display_name_label, setDisplay_name_label] = useState<string>('');
  const [footer_1_label, setFooter_1_label] = useState<string>('');
  const [footer_2_label, setFooter_2_label] = useState<string>('');
  const [footer_3_label, setFooter_3_label] = useState<string>('');
  const [footer_4_label, setFooter_4_label] = useState<string>('');
  const [footer_5_label, setFooter_5_label] = useState<string>('');

  useEffect(() => {
    getItems();
  }, []);

  const onChangeSwitch = (item: IData, index: number) => {
    switch (index) {
      case 0:
        setUnit_name(!unit_name);
        break;
      case 1:
        setDisplay_name(!display_name);
        break;
      case 2:
        setFooter_1(!footer_1);
        break;
      case 3:
        setFooter_2(!footer_2);
        break;
      case 4:
        setFooter_3(!footer_3);
        break;
      case 5:
        setFooter_4(!footer_4);
        break;
      case 6:
        setFooter_5(!footer_5);
        break;
      default:
        return;
    }
  };

  const onChangeInput = (e: any, index: number) => {
    switch (index) {
      case 0:
        setUnit_name_label(e.target.value);
        break;
      case 1:
        setDisplay_name_label(e.target.value);
        break;
      case 2:
        setFooter_1_label(e.target.value);
        break;
      case 3:
        setFooter_2_label(e.target.value);
        break;
      case 4:
        setFooter_3_label(e.target.value);
        break;
      case 5:
        setFooter_4_label(e.target.value);
        break;
      case 6:
        setFooter_5_label(e.target.value);
        break;
      default:
        return;
    }
  };

  const getItems = async (number = 0) => {
    const result: IRestApiResponse = await Services.getListFiles();
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({ value: element.id, label: element.fileName });
      });
      const data_ = result.data || [];
      let first;
      if (number !== 0) {
        first = data_.find((obj: any) => {
          return obj.id === number;
        });
      } else {
        first = data_[0];
      }
      setFiles(a);
      setData(data_);

      // set file is the first item
      setFile(first.id);
      setInternalState(first);
    }
  };

  const setInternalState = (item: ISettingFiles) => {
    setId(item.id);
    setUnit_name_label(item.companyName || '');
    setDisplay_name_label(item.fileName || '');
    setFooter_1_label(item.footer1 || '');
    setFooter_2_label(item.footer2 || '');
    setFooter_3_label(item.footer3 || '');
    setFooter_4_label(item.footer4 || '');
    setFooter_5_label(item.footer5 || '');
    setUnit_name(item.showCompanyName || false);
    setDisplay_name(item.showFileName || false);
    setFooter_1(item.showFooter1 || false);
    setFooter_2(item.showFooter2 || false);
    setFooter_3(item.showFooter3 || false);
    setFooter_4(item.showFooter4 || false);
    setFooter_5(item.showFooter5 || false);
  };

  const handleChangeFile = (event: SelectChangeEvent) => {
    const index = parseInt(event.target.value);
    setFile(event.target.value as string);
    const fileData = data.find((obj: any) => {
      return obj.id === index;
    });
    setInternalState(fileData);
  };

  const onClick = async () => {
    const params: ISettingFiles = {
      id: id,
      companyName: unit_name_label,
      fileName: display_name_label,
      footer1: footer_1_label,
      footer2: footer_2_label,
      footer3: footer_3_label,
      footer4: footer_4_label,
      footer5: footer_5_label,
      showCompanyName: unit_name,
      showFileName: display_name,
      showFooter1: footer_1,
      showFooter2: footer_2,
      showFooter3: footer_3,
      showFooter4: footer_4,
      showFooter5: footer_5,
    };
    const result: IRestApiResponse = await Services.updateSettingFiles(params);
    if (!result || !result.success) return;
    if (result.success) {
      getItems(id);
    }
  };

  const onClickReset = async () => {
    const fileData = data.find((obj: any) => {
      return obj.id === id;
    });
    const fileType = fileData.fileType;
    const result: IRestApiResponse = await Services.resetFile({ fileType });
    if (!result || !result.success) return;
    if (result.success) {
      getItems(id);
    }
  };

  const onClickResetAllFiles = async () => {
    const result: IRestApiResponse = await Services.resetFile();
    if (!result || !result.success) return;
    if (result.success) {
      // Nếu muốn giữ nguyên màn hình thì truyền params: id - 1
      getItems();
    }
  };

  const renderItem = (item: IData, index: number, checked: boolean, placeholder: string) => {
    return (
      <div style={{ display: 'flex', gap: 20, marginTop: 30, alignItems: 'center' }}>
        <div style={{ minWidth: 100 }}>{item.label}</div>
        <div style={{ width: 350 }}>
          <InputCustom value={placeholder} handleChange={(e) => onChangeInput(e, index)} w={350} />
        </div>
        <SwitchCustom
          checked={checked}
          label={checked ? item.switchEnable : item.switchDisable}
          handleChange={() => onChangeSwitch(item, index)}
        />
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 20, alignItems: 'center', marginBottom: 50 }}>
        <div>File: </div>
        <div style={{ width: 400 }}>
          <SelectCustom handleChange={handleChangeFile} value={file} data={files} />
        </div>
      </div>
      {renderItem(dummyFiles[0], 0, unit_name, unit_name_label)}
      {renderItem(dummyFiles[1], 1, display_name, display_name_label)}
      {renderItem(dummyFiles[2], 2, footer_1, footer_1_label)}
      {renderItem(dummyFiles[3], 3, footer_2, footer_2_label)}
      {renderItem(dummyFiles[4], 4, footer_3, footer_3_label)}
      {renderItem(dummyFiles[5], 5, footer_4, footer_4_label)}
      {renderItem(dummyFiles[6], 6, footer_5, footer_5_label)}
      <div style={{ marginLeft: 200, marginTop: 40, gap: 20 }}>
        <Button variant='contained' onClick={onClick}>
          LƯU
        </Button>
        <Button variant='contained' onClick={onClickReset} style={{ marginLeft: 20, marginRight: 20 }}>
          RESET file này
        </Button>
        <Button variant='contained' onClick={onClickResetAllFiles}>
          RESET toàn bộ
        </Button>
      </div>
    </div>
  );
}

export default FileSettingItem;
