import { Button, Checkbox, Grid, SelectChangeEvent } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';

type Props = {
  typeEdit?: any;
  okeAction: (typeEdit: any, b: boolean) => void;
};

function TypeHeader({ typeEdit, okeAction }: Props): JSX.Element {
  const [stateBill, setStateBill] = useState(typeEdit);
  useEffect(() => {
    setStateBill(typeEdit);
  }, [typeEdit]);

  const handleChangeInputState = (event: SelectChangeEvent, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.value });
  };
  const handleChangeCheckState = (event: any, idElement: string) => {
    setStateBill({ ...stateBill, [idElement]: event.target.checked });
  };

  return (
    <div>
      <h3>Thêm sửa loại sản phẩm</h3>
      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid item xs={12} sm={2}>
          <InputCustom
            label='Mã loại'
            value={stateBill?.code}
            handleChange={(e) => handleChangeInputState(e, 'code')}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputCustom
            label='Tên loại'
            value={stateBill?.name}
            handleChange={(e) => handleChangeInputState(e, 'name')}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Checkbox checked={stateBill?.stockAble} onChange={(e) => handleChangeCheckState(e, 'stockAble')} /> Lưu kho
        </Grid>
        <Grid item xs={12} sm={2}>
          <Checkbox checked={stateBill?.truckReport} onChange={(e) => handleChangeCheckState(e, 'truckReport')} />
          Xuất tổng hợp xe
        </Grid>
        <Grid item xs={12} sm={2}>
          <Checkbox checked={stateBill?.productReport} onChange={(e) => handleChangeCheckState(e, 'productReport')} />
          Xuất tổng hàng
        </Grid>
        <Grid item xs={12} sm={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              // disabled={stateBill.id}
              onClick={() => {
                okeAction(stateBill, false);
              }}
            >
              Thêm
            </Button>
            <Button
              variant='contained'
              style={{ paddingLeft: 30, paddingRight: 30 }}
              disabled={!stateBill.id}
              onClick={() => {
                okeAction(stateBill, true);
              }}
            >
              Sửa
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default TypeHeader;
