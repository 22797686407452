import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { BgLogin } from 'assets/images';
import { AppRoutes } from 'helpers/app.routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InputWrapper } from '../../presents';
import { Services } from '../../services';
import style from './styles.module.scss';

interface IAccount {
  ID: string;
  password: string;
}

const LoginBody = (): JSX.Element => {
  const navigate = useNavigate();
  const [account, setAccount] = useState<IAccount>({
    ID: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onChangeID = (event: any) => {
    return setAccount({ ...account, ID: event.target.value });
  };

  const onChangePW = (event: any) => {
    return setAccount({ ...account, password: event.target.value });
  };

  const handleLogin = async () => {
    if (!account.ID || !account.password) {
      return;
    }
    const result: IRestApiResponse = await Services.login({ username: account.ID, password: account.password });
    if (!result || !result.success) return;
    if (result?.success) {
      navigate(AppRoutes.home);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div
      className={style.loginWrap}
      style={{
        backgroundImage: `url(${BgLogin})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        position: 'absolute',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        top: 0,
      }}
    >
      <div className={style.centerLogin}>
        <p className={style.appName}>Login:</p>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '35ch' },
          }}
          noValidate
          autoComplete='off'
        >
          <InputWrapper
            id='id'
            label='ID'
            variant='outlined'
            onChange={onChangeID}
            value={account.ID}
            onKeyDown={handleKeyPress}
          />
          <InputWrapper
            id='password'
            label='password'
            variant='outlined'
            onChange={onChangePW}
            value={account.password}
            type={showPassword ? 'text' : 'password'}
            onKeyDown={handleKeyPress}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        <div>
          <Button size='large' onClick={handleLogin}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginBody;
