import CarSynthesisBody from 'containers/CarSynthesisBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function CarSynthesisPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <CarSynthesisBody />
    </div>
  );
}

export default CarSynthesisPage;
