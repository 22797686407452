import TypeSyntheticBody from 'containers/TypeSyntheticBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function TypeSyntheticPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <TypeSyntheticBody />
    </div>
  );
}

export default TypeSyntheticPage;
