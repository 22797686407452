import { Button, Grid } from '@mui/material';
import InputCustom from 'presents/InputCustom/Index';
import React from 'react';

type Props = {
  total: any;
  actionExport: () => void;
  disabled: boolean;
  actionUpdate?: any;
};

function Footer({ total, disabled, actionExport, actionUpdate }: Props): JSX.Element {
  return (
    <div style={{ marginBottom: 20 }}>
      {/*<Grid item xs={12} sm={1} sx={{ display: { xs: 'none', sm: 'block' } }} style={{ marginBottom: 20 }}>*/}
      {/*  <Button*/}
      {/*    variant='contained'*/}
      {/*    color='primary'*/}
      {/*    onClick={() => {*/}
      {/*      actionUpdate();*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Cập nhật hóa đơn*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} style={{ flexDirection: 'row' }}>
          <InputCustom
            label='Tổng tiền'
            value={total.toLocaleString('it-IT', {
              style: 'currency',
              currency: 'VND',
            })}
            handleChange={() => undefined}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='primary' onClick={actionExport} disabled={disabled}>
            Xuất Hoá Đơn
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
