import { Button, Grid } from '@mui/material';
import moment from 'moment';
import DatePickCustom from 'presents/DatePickCustom/Index';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { paramsFun } from '../../../helpers/help';
import PreviewPDF from '../../PreviewPDF';

function WarehouseFooter(): JSX.Element {
  const [fileLink, setFileLink] = useState('');
  const [value, setValue] = useState<any>(new Date());
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  const suggestBuy = () => {
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/proposal/excel?' +
      paramsFun({ date: moment(value.$d).format('YYYY/MM/DD') });

    setFileLink(link);
    return toast('Download thành công!');
  };

  const suggestBuyPDF = () => {
    console.log('value use ', value);
    const link =
      process.env.REACT_APP_API +
      '/api/v1/report/proposal?' +
      paramsFun({ date: moment(value.$d).format('YYYY/MM/DD') });
    setFilePreview(link);
    setTitle('Đề xuất nhập hàng: ');
    // window.open(link, '_blank');
  };

  const handleChange = async (newValue: any) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid container spacing={{ sm: 4, xs: 1 }}>
        <Grid item xs={12} sm={6}>
          <DatePickCustom label='Ngày' value={value} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='contained' onClick={suggestBuy}>
              Đề xuất nhập hàng
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='contained' onClick={suggestBuyPDF}>
              Đề xuất nhập hàng PDF
            </Button>
          </div>
        </Grid>
      </Grid>
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title={'file'} />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </div>
  );
}

export default WarehouseFooter;
