import { Button, Checkbox, Grid, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import paginate from 'Common';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';

import { PAGE_SIZE } from '../../../constants';
import { colors } from '../../../constants/colors';

type Props = {
  data: any;
  choseBill: (bill: any) => void;
  actionSearch?: any;
};

function ProductTable({ data, choseBill, actionSearch }: Props): JSX.Element {
  const [row_, setRow_] = useState<any>(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const [dataPag, setDataPag] = useState(paginate(data, PAGE_SIZE, page));
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setDataPag(paginate(data, rowsPerPage, newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setDataPag(paginate(data, parseInt(event.target.value, 10), 0));
    setPage(0);
  };

  useEffect(() => {
    setDataPag(paginate(data, PAGE_SIZE, 0));
  }, [data]);

  const search = () => {
    actionSearch(searchText);
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <h3>Danh sách sản phẩm cung cấp</h3>
      <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
        <Grid item xs={12} sm={4}>
          <InputCustom
            label='Tìm kiếm Sản phẩm'
            value={searchText}
            handleChange={handleChangeInput}
            keyPress={(ev) => {
              if (ev.key === 'Enter') {
                actionSearch(searchText);
              }
            }}
          />
        </Grid>
        <Grid>
          <Button
            variant='contained'
            color='primary'
            onClick={search}
            sx={{ width: { xs: '100%', sm: 'auto' }, marginTop: { xs: 2, sm: 0 } }}
          >
            Tìm kiếm
          </Button>
        </Grid>
      </div>
      <TableContainer component={Paper} sx={{ width: { xs: '96vw', sm: 'unset' } }}>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Mã SP</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Tên SP
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Giá Nhập
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Giá Bán
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Quy cách
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Lưu kho
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align='left'>
                Loại
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPag?.map((row: any, index: number) => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  ':hover': { backgroundColor: '#EFEFEF' },
                }}
                onClick={() => {
                  choseBill(row);
                  setRow_(index);
                }}
                key={row.id}
                style={{ cursor: 'pointer', backgroundColor: index === row_ ? colors.chooseItem : 'transparent' }}
              >
                <TableCell>{row.code}</TableCell>
                <TableCell align='left'>{row?.name}</TableCell>
                <TableCell align='left'>{row?.importPrice?.toLocaleString('it-IT')}</TableCell>
                <TableCell align='left'>{row?.salePrice?.toLocaleString('it-IT')}</TableCell>
                <TableCell align='left'>{row?.specifications}</TableCell>
                <TableCell align='left'>
                  <Checkbox checked={row?.stockAble} disabled style={{ padding: 0, margin: 0 }} />
                </TableCell>
                <TableCell align='left'>{row?.category?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={data?.length}
          component='div'
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default ProductTable;
