import ProductBody from 'containers/ProductBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function ProductPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <ProductBody />
    </div>
  );
}

export default ProductPage;
