import React from 'react';

type Props = {
  link: string;
  title?: string;
};

const PreviewPDF = (props: Props) => {
  return (
    <div style={{ marginTop: 40 }}>
      <div>{props.title}</div>
      <iframe src={props.link} title='CarSynthesisHeader' width='700' height='900' />
    </div>
  );
};

export default PreviewPDF;
