import SchoolBody from 'containers/SchoolBody';

import SlideBar from '../../components/SlideBar';

function SchoolPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <SchoolBody />
    </div>
  );
}

export default SchoolPage;
