import Bill from 'components/ExportBilling/Bill';
import EditProduct from 'components/ExportBilling/EditProduct';
import ExportBillingHeader from 'components/ExportBilling/ExportBillingHeader';
import Footer from 'components/ExportBilling/Footer';
import ListOfProducts from 'components/ExportBilling/ListOfProducts';
import { paramsFun, removeObjectWithId, uuid } from 'helpers/help';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PreviewPDF from '../../components/PreviewPDF';
import { TFlatOrderDTO, TProductDTO } from '../../DTO';
import { Services } from '../../services';
import style from './style.module.scss';

const initialState = {
  id: '',
  category: {
    id: '',
    code: '',
    name: '',
    stockAble: false,
  },
  idCustom: null,
  name: '',
  count: 0,
  salePrice: 0,
  totalPrice: 0,
  specifications: '',
};

interface IState {
  resetHighLightLeft: boolean;
  resetHighLightRight: boolean;
}

function ExportBillingBody(): JSX.Element {
  const [listOfProducts, setListOfProducts] = useState<TProductDTO[]>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [product, setProduct] = useState<any>(initialState);
  const [listBill, setListBill] = useState<any[]>([]);
  const [school, setSchool] = useState();
  const [date, setDate] = useState<any>(new Date());
  const [fileLink, setFileLink] = useState('');
  const [orderCode, setOrderCode] = React.useState('');
  const [needRefreshCustomer, setNeedRefreshCustomer] = useState<boolean>(false);
  const [isProductInBill, setIsProductInBill] = useState(false);
  const [state, setState] = useState<IState>({
    resetHighLightLeft: false,
    resetHighLightRight: false,
  });
  const [filePreview, setFilePreview] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    getListProduct();
  }, []);

  const getListProduct = async (customerId?: string) => {
    const listProduct: IRestApiResponse = await Services.getAllProduct({ customerId });
    if (!listProduct || !listProduct.success) return;
    if (listProduct.success) {
      setListOfProducts(listProduct.data);
    }

    return resetHighLightLeft();
  };

  const getBill = async (school_: undefined | string, date_: any) => {
    const parseDate = moment(date_).format('YYYY/MM/DD') || '2022/10/14';
    if (school_ && date_) {
      const result = await Services.getListOrder({ customerId: school_ as any, date: parseDate });
      if (result.success && result.data !== null) {
        const billTemp: any = result.data;
        const detailBill: TFlatOrderDTO[] = [];
        const dataItem = {
          idOrder: billTemp?.id,
          date: billTemp?.date,
          school: billTemp?.customer?.id,
          isDone: billTemp?.isDone,
        };
        let totalPrice_ = 0;
        for (let i = 0; i < billTemp.detail.length; i++) {
          const flatBill = {
            index: i,
            ...dataItem,
            ...{
              idProduct: billTemp.detail[i].productItem?.id,
              code: billTemp.detail[i].productItem?.code,
              name: billTemp.detail[i].productItem?.name,
              specifications: billTemp.detail[i].productItem?.specifications,
              salePrice: billTemp.detail[i].price,
              count: billTemp.detail[i].quantity,
              actuallyReceived: billTemp.detail[i].actuallyReceived,
              // additionalQuantity: billTemp.detail[i].additionalQuantity,
              idSp: billTemp.detail[i].id,
              totalPrice: billTemp.detail[i].actuallyReceived * billTemp.detail[i].price,
              categoryName: billTemp.detail[i].categoryName,
            },
          };
          detailBill.push(flatBill as any);
          totalPrice_ += billTemp.detail[i].actuallyReceived * billTemp.detail[i].price;
        }
        setListBill([...detailBill]);
        setOrderCode(billTemp.code);
        setTotalPrice(totalPrice_);
      } else {
        // toast('Chưa có hóa đơn');
        setListBill([]);
        setOrderCode('');
        setTotalPrice(0);
      }
    }
    return resetHighLightLeft();
  };

  const setSchool_ = async (school_: any) => {
    await setSchool(school_);
    await getListProduct(school_);
    await getBill(school_, date);

    return resetAllHighLight();
  };

  const setDate_ = async (date_: any) => {
    const parseDate_ = moment(date_?.$d.toISOString()).format('YYYY/MM/DD');
    await setDate(parseDate_);
    await getBill(school, parseDate_);

    return resetAllHighLight();
  };

  const choseProduct = (data: any, fromBill = false) => {
    setProduct(data);
    setIsProductInBill(fromBill);
    return fromBill ? resetHighLightLeft() : resetHighLightRight();
  };

  const addAction = (data: any) => {
    choseProduct(initialState);
    const temp = [...listBill];
    temp.push(data);
    setListBill(temp);
    let totalMoneyTmp = 0;
    temp.forEach((element: any) => {
      totalMoneyTmp += element.totalPrice;
    });
    setTotalPrice(totalMoneyTmp);

    resetAllHighLight();
  };

  const createBill = async () => {
    if (!school || !date) {
      return toast('Hãy chọn ngày và trường học để tạo hóa đơn!');
    }
    const listItem: any[] = [];

    listBill.forEach((item: any) => {
      listItem.push({
        productId: item?.idProduct,
        quantity: parseFloat(item?.count),
        actuallyReceived: parseFloat(item?.actuallyReceived),
      });
    });

    const isUpdate = listBill && listBill.length >= 0;
    const result = await Services.createOrder({
      id: listBill[0]?.idOrder || '',
      customerId: school,
      date: moment(date).format('YYYY-MM-DD'),
      items: listItem,
    } as any);

    if (!result || !result.success) return;
    if (result.success) {
      if (result?.data?.code === '400') {
        toast(result?.data?.message);
        return;
      }

      let totalMoneyTmp = 0;
      listBill.forEach((element: any) => {
        totalMoneyTmp += element.totalPrice;
      });
      setTotalPrice(totalMoneyTmp);

      const tempL = [...listBill];

      tempL.forEach((element: any) => {
        element.billCode = result.data?.code;
      });

      setNeedRefreshCustomer(!needRefreshCustomer);
      await getBill(school, date);
    }

    resetAllHighLight();
    return isUpdate ? toast('Update hoá đơn thành công') : toast('Tạo hoá đơn thành công');
  };

  const onExport = async () => {
    if (!school || !date) {
      return toast('Hãy chọn ngày và trường học để xuất hóa đơn!');
    }
    const parseDate_ = date != new Date() ? date : moment().format('YYYY/MM/DD');
    const link =
      process.env.REACT_APP_API + '/api/v1/report/bill/excel?' + paramsFun({ date: parseDate_, customerId: school });

    setFileLink(link);
    resetAllHighLight();
    return toast('Download thành công!');
  };

  const onPreview = async () => {
    if (!school || !date) {
      return toast('Hãy chọn ngày và trường học để xuất hóa đơn!');
    }
    const parseDate_ = date != new Date() ? date : moment().format('YYYY/MM/DD');
    const link =
      process.env.REACT_APP_API + '/api/v1/report/bill?' + paramsFun({ date: parseDate_, customerId: school });

    resetAllHighLight();
    setFilePreview(link);
    setTitle('Hóa đơn: ');
    // window.open(link, '_blank');
  };

  const updateListBill = (item: any) => {
    const temp = [...listBill];
    const foundIndex = temp.findIndex((x) => x.idCustom === item.idCustom);
    temp[foundIndex] = item;
    setListBill(temp);
    let totalMoneyTmp = 0;
    temp.forEach((element: any) => {
      totalMoneyTmp += element.totalPrice;
    });
    setTotalPrice(totalMoneyTmp);
    choseProduct(initialState);

    resetAllHighLight();
  };

  const deleteItemListBill = (item: any) => {
    let temp = [...listBill];
    temp = removeObjectWithId(temp, item.idCustom);
    setListBill(temp);
    let totalMoneyTmp = 0;
    temp.forEach((element: any) => {
      totalMoneyTmp += element.totalPrice;
    });
    setTotalPrice(totalMoneyTmp);
    choseProduct(initialState);

    resetAllHighLight();
  };

  const refreshBill = () => {
    setListBill([]);
  };

  const actionSearch = async (key: string) => {
    const listProduct: IRestApiResponse = await Services.getAllProduct({ keyword: key || '', customerId: school });
    setListOfProducts(listProduct.data);

    resetHighLightLeft();
  };

  const resetHighLightLeft = () => setState({ ...state, resetHighLightLeft: !state.resetHighLightLeft });

  const resetHighLightRight = () => setState({ ...state, resetHighLightRight: !state.resetHighLightRight });

  const resetAllHighLight = () => {
    resetHighLightLeft();
    resetHighLightRight();
  };

  return (
    <>
      <ExportBillingHeader
        setSchool={setSchool_}
        setDate={setDate_}
        refreshBill={refreshBill}
        orderCode={orderCode}
        needRefreshCustomer={needRefreshCustomer}
      />
      <div className={style.homeContainer}>
        <div style={{ flex: 2 }}>
          <ListOfProducts
            data={listOfProducts}
            choseProduct={choseProduct}
            actionSearch={actionSearch}
            resetHighLightLeft={state.resetHighLightLeft}
          />
        </div>
        <div style={{ flex: 3 }}>
          <EditProduct
            product={product}
            actionAdd={addAction}
            actionEdit={updateListBill}
            actionDelete={deleteItemListBill}
            isProductInBill={isProductInBill}
          />
          <Bill listBill={listBill} choseProduct={choseProduct} resetHighLightRight={state.resetHighLightRight} />
        </div>
      </div>
      <Footer total={totalPrice} actionExport={onExport} actionPreview={onPreview} actionSave={createBill} />
      <div style={{ display: 'none' }}>
        <iframe src={fileLink} title='iframe-output-order' />
      </div>
      {filePreview && <PreviewPDF link={filePreview} title={title} />}
    </>
  );
}

export default ExportBillingBody;
