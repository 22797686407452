import CarBody from 'containers/CarBody';

import SlideBar from '../../components/SlideBar';

function CarPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <CarBody />
    </div>
  );
}

export default CarPage;
