import SchoolHeader from 'components/School/SchoolHeader';
import SchoolTable from 'components/School/SchoolTable';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

const initialState = {
  code: '',
  name: '',
  phoneNumber: '',
  address: '',
  email: '',
  district: '',
  truckId: '',
  status: false,
  truck: {
    code: '',
    driverName: '',
    type: '',
  },
};

function SchoolBody(): JSX.Element {
  const [itemChose, setItemChose] = useState({ ...initialState });
  const [data, setData] = useState();

  useEffect(() => {
    getItems();
  }, []);

  const handleItemEdit = (item: any, edited = false) => {
    setItemChose({ ...initialState });

    if (edited) {
      Services.updateCustomer(item).then((results) => {
        getItems();
      });
    } else {
      Services.addCustomer(item).then((results) => {
        getItems();
      });
    }
  };

  const getItems = async () => {
    const result: IRestApiResponse = await Services.getAllCustomer();
    setData(result.data);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SchoolHeader typeEdit={itemChose} okeAction={handleItemEdit} />
      {data && <SchoolTable data={data} choseSchool={setItemChose} />}
    </div>
  );
}

export default SchoolBody;
