import _axios from './axiosInstance';

export const login = async (params: ILoginParams): Promise<IRestApiResponse> => {
  const credentials = JSON.stringify(params);
  return _axios.post('/api/auth/login', credentials);
};

export const logout = async (): Promise<void> => {
  await _axios.get('/api/auth/logout');
  sessionStorage.clear();
};

/**
 * CATEGORY
 */

export const getAllCategory = async (): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/category');
};

export const addCategory = async (params?: IAddCategory): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/category', parseParams);
};

export const updateCategory = async (params?: IUpdateCategory): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/category/update', parseParams);
};

export const removeCategory = async (id: number): Promise<IRestApiResponse> => {
  return _axios.delete('/api/v1/category', { params: { id } });
};
// ========== END CATEGORY

/**
 * CUSTOMER
 */

export const getAllCustomer = async (params?: { date?: string }): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/customer', { params });
};

export const addCustomer = async (params?: IAddCustomer): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/customer', parseParams);
};

export const updateCustomer = async (params?: IUpdateCustomer): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/customer/update', parseParams);
};

export const removeCustomer = async (id: number): Promise<IRestApiResponse> => {
  return _axios.delete('/api/v1/customer', { params: { id } });
};
// ========== END CUSTOMER

/**
 * DELIVERY
 */

export const deliveryForCustomerAndDate = async (params?: IGetDelivery): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/delivery', { params });
};

export const exportDelivery = async (params?: IGetDelivery): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/delivery/export', { params });
};
// ========== END DELIVERY

/**
 * GOODS
 */

export const importGoods = async (params?: IGoods): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/goods-received', parseParams);
};
// ========== END GOODS

/**
 * ORDER
 */

export const getListOrder = async (params?: IGetListOrderParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/order', { params });
};

export const createOrder = async (params?: ICreateOrderParams): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/order/create', parseParams);
};

export const updateOrder = async (params?: IUpdateOrderParams): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/order/update', parseParams);
};

export const getDetailOrder = async ({
  date,
  customerId,
}: {
  date: string;
  customerId: number;
}): Promise<IRestApiResponse> => {
  return _axios.get(`/api/v1/order?date=${date}&customerId=${customerId}`);
};

export const getOderCode = async (params?: IGetOrderCode): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/order/code', { params });
};
// ========== END ORDER

/**
 * PRODUCT
 */

export const getAllProduct = async (params?: IGetAllProductParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/product-item', { params });
};
export const getAllProductInventory = async (params?: IGetAllProductParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/goods-received', { params });
};

export const addProduct = async (params?: IAddProductParams): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/product-item', parseParams);
};

export const updateProduct = async (params?: IUpdateProductParams): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/product-item/update', parseParams);
};

export const removeProduct = async (id: number): Promise<IRestApiResponse> => {
  return _axios.delete('/api/v1/product-item', { params: { id } });
};

export const updateProductQuotes = async (params?: IUpdateProductQuotesParams): Promise<IRestApiResponse> => {
  const parseParams = JSON.stringify(params);
  return _axios.post('/api/v1/price-config/update', parseParams);
};
// ========== END PRODUCT

/**
 * REPORTS
 */

export const getCategoryReport = async (params: IReportCategoryParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/category', { params });
};

export const exportCategoryReport = async (params?: IReportCategoryParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/category/export', { params });
};

export const getProductReport = async (date?: string): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/product', { params: { date } });
};

export const exportProductReport = async (date?: string): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/product/export', { params: { date } });
};

export const getTruckReport = async (params: IReportTruckParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/truck', { params });
};

export const exportTruckReport = async (params: IReportTruckParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/truck/export', { params });
};

export const getDebtReport = async (params: IDeptParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/debt', { params });
};

export const getMonthReport = async (params: IMonthlyParams): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/report/month', { params });
};

export const getBillExport = async (params: IBillParams): Promise<IRestApiResponse> => {
  return _axios.post('/api/v1/report/month', params);
};
// ========== END REPORTS

/**
 * TRUCK
 */

export const getListCar = async (): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/truck');
};

export const addNewCar = async (car: any): Promise<IRestApiResponse> => {
  return _axios.post('/api/v1/truck', car);
};

export const updateCar = async (params: any): Promise<IRestApiResponse> => {
  return _axios.post('/api/v1/truck/update', params);
};

export const removeCar = async (id: number): Promise<IRestApiResponse> => {
  return _axios.delete('/api/v1/truck', { params: { id } });
};
// ========== END TRUCK

/**
 * FILES
 */

export const getListFiles = async (): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/file-setting');
};

export const updateSettingFiles = async (params: ISettingFiles): Promise<IRestApiResponse> => {
  return _axios.post('/api/v1/file-setting/update', params);
};

export const resetFile = async (params?: IFileReset): Promise<IRestApiResponse> => {
  return _axios.get('/api/v1/file-setting/reset', { params });
};
// ========== END FILES
