import { Grid } from '@mui/material';
import WarehouseHeader from 'components/Warehouse/WarehouseHeader';
import WarehouseTable from 'components/Warehouse/WarehouseTable';
import { TProductDTO } from 'DTO';
import InputCustom from 'presents/InputCustom/Index';
import React, { useEffect, useState } from 'react';
import { Services } from 'services';

function WarehouseBody(): JSX.Element {
  const [productChose, setSPChose] = useState();
  const [listOfProducts, setListOfProducts] = useState<TProductDTO[]>([]);
  const [searchText, setSearchText] = useState('');
  const choseSP = (product: any) => {
    setSPChose(product);
  };

  useEffect(() => {
    init();
  }, []);

  const init = async (key?: string) => {
    const listProduct: IRestApiResponse = await Services.getAllProductInventory({ keyword: key || '' });
    if (!listProduct || !listProduct.success) return;
    if (listProduct.success) {
      setListOfProducts(listProduct.data);
    }
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 20 }}>
        <Grid item xs={12} sm={4}>
          <InputCustom
            label='Sản phẩm'
            value={searchText}
            handleChange={handleChangeInput}
            keyPress={(ev) => {
              if (ev.key === 'Enter') {
                init(searchText);
              }
            }}
          />
        </Grid>
      </div>
      <WarehouseTable data={listOfProducts} choseSP={choseSP} />
      <WarehouseHeader
        productEdit={productChose}
        okeAction={function (productEdit: any): void {
          throw new Error('Function not implemented.');
        }}
        reload={init}
        keyword={searchText}
      />
    </div>
  );
}

export default WarehouseBody;
