import ExportBillingBody from 'containers/ExportBillingBody';
import React from 'react';

import SlideBar from '../../components/SlideBar';

function ExportBillingPage(): JSX.Element {
  return (
    <div>
      <SlideBar />
      <ExportBillingBody />
    </div>
  );
}

export default ExportBillingPage;
