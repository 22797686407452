import { Grid, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import DatePickCustom from 'presents/DatePickCustom/Index';
import InputCustom from 'presents/InputCustom/Index';
import SelectCustom from 'presents/SelectCustom/Index';
import React, { useEffect, useState } from 'react';

import { formatDate } from '../../../Common';
import { colors } from '../../../constants/colors';
import { Services } from '../../../services';

interface IProps {
  setSchool?: any;
  refreshBill?: any;
  setDate?: any;
  orderCode?: any;
  needRefreshCustomer?: any;
}

function ExportBillingHeader(props: IProps): JSX.Element {
  const [value, setValue] = React.useState<any>(new Date());
  const [school, setSchool] = React.useState('');
  const [car, setCar] = React.useState('');
  const [schools, setSchools] = useState<any[]>([]);

  const handleChangeSchool = async (event: SelectChangeEvent) => {
    const index = parseInt(event.target.value) - 1;
    setSchool(event.target.value as string);
    props.setSchool(event.target.value as string);
    setCar(schools[index].car);
    props.refreshBill();
    await getItems();
  };

  const handleChange = async (newValue: any) => {
    setValue(newValue);
    props.setDate(newValue);
    props.refreshBill();
    await getItems(newValue);
  };

  useEffect(() => {
    getItems();
  }, [props.needRefreshCustomer]);

  const getItems = async (newValue?: any) => {
    const date = newValue ? newValue : value;
    const result: IRestApiResponse = await Services.getAllCustomer({ date: formatDate(date) });
    if (!result || !result.success) return;
    const a: any[] = [];
    if (result.success) {
      result.data.forEach((element: any) => {
        a.push({
          value: element.id,
          label: element.name,
          existedBill: element.existedBill,
          car: element.truck?.driverName + ' ✧ ' + element.truck?.code,
        });
      });
      setSchools(a);
    }
  };

  return (
    <div>
      <Grid container spacing={{ xs: 1, sm: 8 }}>
        <Grid item xs={12} sm={2}>
          <InputCustom label='Mã Hoá Đơn' value={props.orderCode} disabled />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <InputCustom label='Xe' value={car} disabled />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCustom value={school} handleChange={handleChangeSchool} data={schools} label='Trường Học' />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DatePickCustom label='Ngày' value={value} handleChange={handleChange} />
        </Grid>
      </Grid>
      <div style={{ height: 1, width: '100%', backgroundColor: colors.main, marginTop: 20, marginBottom: 2 }} />
    </div>
  );
}

export default ExportBillingHeader;
