import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Logo } from '../../assets/images';
import HandledImage from '../../components/HandledImage';
import { AppRoutes } from '../../helpers/app.routes';

type Props = {
  content: React.ReactNode;
};

function MainLayout(props: Props): JSX.Element {
  const { content } = props;
  const navigate = useNavigate();

  return (
    <div>
      <div onClick={() => navigate(AppRoutes.home)} className='headerLogo'>
        <HandledImage style={{ width: 50, height: 50 }} src={Logo} />
        <div style={{ color: 'black' }}>Nông Trại Việt</div>
      </div>
      {content}
    </div>
  );
}

export default MainLayout;
